

import moment from "moment";
import TableActions from "../table-actions";
import ConfirmModal from "../confirm-modal";
import modals from "../../mixins/modals";
import EditContactListModal from "../emails/edit-contact-list-modal";
import UserContactGroupBulkOperationModal from "./user-contact-group-bulk-operation-modal";

export default {
    name: "UserContactGroupCard",
    components: {TableActions, ConfirmModal, EditContactListModal, UserContactGroupBulkOperationModal},
    mixins: [modals],
    props: {
        contact: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            contactListToEdit: null,
            contactListsUpdated: moment().unix(),
            deleteFromContactListId: null,
            config: {
                ajaxUrl: '/api/emails/contact_lists',
                search: true,
                pagination: 25,
                columns: [
                    {
                        headline: "Name",
                        sort: true,
                    },
                    {
                        headline: "Contacts",
                        breakpoint: "sm",
                        sort: true,
                    },
                    {
                        headline: "Temporary",
                        breakpoint: "all",
                    },
                    {
                        headline: "Author",
                        breakpoint: "sm",
                        sort: true,
                    },
                    {
                        headline: "Last Change",
                        breakpoint: "all",
                        sort: true,
                    },
                    {
                        headline: "Created At",
                        breakpoint: 'all',
                        sort: true,
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                        ignoreEmpty: true,
                    },
                    {
                        headline: "Description",
                        breakpoint: "all",
                    },
                    {
                        headline: "Last Action",
                        breakpoint: "all",
                    },
                    {
                        headline: "Changed by",
                        breakpoint: "all",
                    }
                ],
                selectAll: true,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
                // hideEmptyColumns: true
            },
            pageChanged: false,
            ajaxRows: [],
            contactList: {},
            preSelectedRows: [],
            preSelectedRowIds: [],
            selectedRows: [],
            bulkFilters: {},
            bulkModal: null,
        }
    },
    computed: {
        filters() {
            return {contactId: this.contact.id}
        },
    },
    watch: {
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].contactListId)) {
                        this.preSelectedRowIds.push(rows[i].contactListId);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].contactListId;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.contactListId).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }

            this.bulkFilters = {
                ids: this.preSelectedRowIds
            }
        },
    },
    mounted() {
    },
    methods: {
        deleteContactListContact(id) {
            this.deleteFromContactListId = id;
            this.openModal(this.$refs.deleteContactListContactModal);
        },
        async doDeleteContactListContact(id) {
            await this.$axios.get('/api/contact_lists/' + id).then((response) => {
                this.contactList = response.data
            });

            this.$axios.post(`/api/contact_lists/remove_contact/${this.contact.id}`, {
                contactListIds: [this.contactList.id],
            },
            ).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "updated",
                    message: "Group updated",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.contactListsUpdated = moment().unix()
            });
        },
        cancelDeleteContactListContact() {
            this.deleteFromContactListId = null
        },
        editContactList(id) {
            this.contactListToEdit = id;

            this.editModal = this.openModal(this.$refs.editContactListModal);
        },

        closeContactListModal() {
            this.closeModal(this.editModal);
        },
        onContactListUpdated() {
            this.contactListsUpdated = moment().unix()
        },
        onRowsUpdated(data) {
            this.pageChanged = false;
            this.ajaxRows = data.rows;
            this.preSelectedRows = structuredClone(this.preSelectedRowIds).map(id => {
                return {
                    key: "contactListId",
                    value: id
                }
            });
        },
        onPageChange() {
            this.pageChanged = true;
        },
        clearPreSelection() {
            this.preSelectedRows = [];
            this.preSelectedRowIds = [];
            this.selectedRows = [];
        },
        openBulkOperationModal() {
            this.bulkModal = this.openModal(this.$refs.userContactBulkOperationModal)
        },
        onBulkSubmitted() {
            this.contactListsUpdated = moment().unix();
            this.clearPreSelection();
            this.closeModal(this.bulkModal);
        },

    }
}
