
import moment from "moment";
import InfoPopover from "../helpers/info-popover.vue";
import KeyContact from "./key-contact";
import optionButtonTooltip from "~/mixins/option-button-tooltip";


export default {
    name: "ContactName",
    components: {InfoPopover, KeyContact},
    mixins: [optionButtonTooltip],
    props: {
        contact: {
            type: Object,
            required: true
        },
        showEmail: {
            type: Boolean,
            default: true
        },
        linkToProfile: {
            type: Boolean,
            default: false,
        },
        showKey: {
            type: Boolean,
            default: true,
        },
        withCompany: {
            type: Boolean,
            default: false,
        },
        showPhoto: {
            type: Boolean,
            default: false,
        },
        showAssistantMarker: {
            type: Boolean,
            default: false,
        },
        assistanceContacts: {
            type: String,
            default: null,
        },
        isContactTypeAssistant: {
            type: Boolean,
            default: false
        },
        openInNewWindow: {
            type: Boolean,
            default: false,
        },
        showPopover: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            contactHovered: moment().unix(),
        }
    },
    computed: {
        displayName() {
            if (this.withCompany) {
                return this.contact.nameWithCompany;
            } else {
                return this.contact.name;
            }
        }
    },
    watch: {
        contactHovered() {
            this.emitClose()
        }
    },
    mounted() {
        this.buildEvents();
    },
    beforeDestroy() {
        if (this.$refs["email-dropdown"]) {
            this.$refs["email-dropdown"].removeEventListener("show.bs.dropdown", this.emitOpen);
            this.$refs["email-dropdown"].removeEventListener("hide.bs.dropdown", this.emitClose);
        }
    },
    methods: {
        resetPopover() {
            this.contactHovered = moment().unix()
            this.$nextTick(() => {
                this.buildEvents();
            })
        },
        buildEvents() {
            if (this.$refs["email-dropdown"]) {
                this.$refs["email-dropdown"].addEventListener("show.bs.dropdown", this.emitOpen);
                this.$refs["email-dropdown"].addEventListener("hide.bs.dropdown", this.emitClose);
            }
        },
        emitClose() {
            this.$emit("popover-closed");

        },
        emitOpen() {
            this.$emit("popover-opened");
        },
    }
}
