
import SaveButton from "../save-button.vue";
import investmentPreferences from "../../mixins/investment-preferences";

export default {
    name: "InvestmentPreferencesModal",
    components: {SaveButton},
    mixins: [investmentPreferences],
    props: {
        id: {
            type: String,
            default: "editInvestmentPreferencesModal"
        },
        contact: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loadingCharacteristics: false,
            saving: false,
            companyCharacteristics: [],
        }
    },
    computed: {
        availableOptions() {
            return this.companyCharacteristics
                .filter(cc => !this.positiveCompanyCharacteristics.map(c => c.id).includes(cc.id))
                .filter(cc => !this.negativeCompanyCharacteristics.map(c => c.id).includes(cc.id));
        }
    },
    watch: {
        'contact.investmentPreferences': {
            handler() {
                this.positiveCompanyCharacteristics = [];
                this.negativeCompanyCharacteristics = [];
                for (let i = 0; i < this.contact.investmentPreferences.length; i++) {
                    if (this.contact.investmentPreferences[i].isPositive) {
                        this.positiveCompanyCharacteristics.push(this.contact.investmentPreferences[i].companyCharacteristic);
                    } else {
                        this.negativeCompanyCharacteristics.push(this.contact.investmentPreferences[i].companyCharacteristic);
                    }
                }
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.loadCompanyCharacteristics();
        })
    },
    methods: {
        submitForm() {

            this.saving = true;

            const positiveCompanyCharacteristicIds = this.positiveCompanyCharacteristics.map(c => c.id);
            const negativeCompanyCharacteristicIds = this.negativeCompanyCharacteristics.map(c => c.id);

            this.updateInvestmentPreferencesForContact(this.contact.id, positiveCompanyCharacteristicIds, negativeCompanyCharacteristicIds)
                .then((response) => {
                    this.$emit("preferences-updated", response.data);
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        loadCompanyCharacteristics() {
            this.loadingCharacteristics = true;
            this.$axios.get('/api/company_characteristics').then((response) => {
                this.companyCharacteristics = response.data['hydra:member'];
            }).finally(() => {
                this.loadingCharacteristics = false;
            });
        }
    }
}
