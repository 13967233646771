
import LoadingSpinner from "../../loading-spinner.vue";
import {ATTENDANCE_OPTIONS} from "../../../mixins/attending";

export default {
    name: "ContactEventParticipationCell",
    components: {LoadingSpinner},
    props: {
        participation: {
            type: Object,
            required: true,
        },
        attributeToEdit: {
            type: String,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
        allowEditEventParticipation: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            participationToEdit: {},
        };
    },
    computed: {
        showCell() {
            if (this.attributeToEdit === 'isAttending') {
                return true;
            }

            if (this.participationToEdit.isAttending === false) {
                return false;
            }

            return true;
        },
        state: {
            get() {
                return this.participationToEdit[`${this.attributeToEdit}State`];
            },
            set(newValue) {
                this.$set(this.participationToEdit, `${this.attributeToEdit}State`, newValue);
            },
        },
        labels() {
            const availableLabels = ATTENDANCE_OPTIONS.map(x => x.label);

            if (this.participationToEdit.participationId) {
                availableLabels.push('Remove');
            }

            return availableLabels;
        },
        attendanceText() {
            if (!this.participationToEdit.participationId) {
                return 'N/A';
            }
            return ATTENDANCE_OPTIONS.find(x => x.value === this.participationToEdit[this.attributeToEdit]).label;
        },
    },
    watch: {
        participation: {
            handler(newValue) {
                if (this.participationToEdit.participationId !== newValue.participationId) {
                    this.$set(this.participationToEdit, 'participationId', newValue.participationId);
                }

                if (this.state === 'viewing') {
                    this.$set(this.participationToEdit, 'isAttending', newValue.isAttending);
                    this.$set(this.participationToEdit, 'hasAttended', newValue.hasAttended);
                }
            },
            deep: true
        },
    },
    created() {
        this.participationToEdit = structuredClone(this.participation);
    },
    methods: {
        updateAttendance(selectedOption) {
            this.state = 'saving';
            console.log('updating', selectedOption, this.participationToEdit);

            let responsePromise;
            if (selectedOption === 'Remove') {
                responsePromise = this.$axios.delete(`/api/contact_event_participations/${this.participationToEdit.participationId}`).then(() => {
                    this.$set(this.participationToEdit, 'isAttending', null);
                    this.$set(this.participationToEdit, 'hasAttended', null);
                    this.$set(this.participationToEdit, 'participationId', null);
                });

            } else {
                this.$set(this.participationToEdit, this.attributeToEdit, ATTENDANCE_OPTIONS.find(x => x.label === selectedOption).value);

                if (this.attributeToEdit === 'hasAttended' && this.participationToEdit.hasAttended === true && this.participationToEdit.isAttending !== true) {
                    this.$set(this.participationToEdit, 'isAttending', true);
                }

                if (this.participationToEdit.participationId) {
                    const data = {
                        isAttending: this.participationToEdit.isAttending,
                        hasAttended: this.participationToEdit.hasAttended,
                    };

                    responsePromise = this.$axios.patch(`/api/contact_event_participations/${this.participationToEdit.participationId}`, data, {
                        headers: {'Content-Type': 'application/merge-patch+json'},
                    });
                } else {
                    const data = {
                        event: this.event['@id'],
                        contact: `/api/contacts/${this.participationToEdit.contactId}`,
                        isAttending: this.participationToEdit.isAttending,
                        hasAttended: this.participationToEdit.hasAttended,
                    }

                    responsePromise = this.$axios.post('/api/contact_event_participations', data).then(response => {
                        this.$set(this.participationToEdit, 'participationId', this.extractNumericId(response.data));
                    });
                }
            }
            responsePromise.then(() => {
                this.state = 'viewing';
                console.log('updated', this.participationToEdit);

                this.$emit("updated", this.participationToEdit);
            }).catch(() => {
                this.state = 'editing';
            });
        },
        editAttendance() {
            this.state = 'editing';
        },
    },
}
