


import EventsTable from "../events/events-table";

export default {
    name: "ContactEventParticipation",
    components: {EventsTable},
    props: {
        initialContact: {
            type: Object,
            required: true,
        },
    },


    data() {
        return {};
    },
    computed: {},

}
