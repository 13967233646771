

import ContactCompanySelect from "./contact-company-select";
import ContactDepartmentSelect from "./contact-department-select";
import ContactTypeSelect from "./contact-type-select";
import ContactInvestorTierSelect from "./contact-investor-tier-select.vue";

export default {
    name: "ContactBulkEdit",
    components: {
        ContactCompanySelect,
        ContactDepartmentSelect,
        ContactTypeSelect,
        ContactInvestorTierSelect
    },
    props: {
        value: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            propertyNamesToChange: {},
            newValues: this.value,
        }
    },
    watch: {
        newValues: {
            handler(val) {
                this.$emit("input", val)
            },
            deep: true,
        }
    }
}
