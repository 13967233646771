

import moment from "moment";
import SaveButton from "./save-button";
import contactList from '~/mixins/contact-list';

export default {
    name: "ContactListModal",
    components: {SaveButton},
    mixins: [contactList],
    props: {
        contacts: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            saving: false,
            contactsWorkingCopy: [],
            contactList: null,
            contactUpdated: moment().unix(),
            formName: "contact-list-form",
            mostUsedContactLists: []
        }
    },
    watch: {
        contacts: {
            handler(val) {
                if (val && val.length) {
                    this.resetWorkingCopy();
                } else {
                    this.contactsWorkingCopy = [];
                }
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loadMostUsedContactLists();
        });
    },
    methods: {
        resetWorkingCopy() {
            this.contactsWorkingCopy = [];
            this.contacts.forEach((c) => {
                this.contactsWorkingCopy.push(structuredClone(c));
            });
            this.$nextTick(() => {
                this.contactUpdated = moment().unix();
            });
        },
        submitForm() {
            this.saving = true;

            const contactIds = this.contactsWorkingCopy.map(contact => contact.id);
            this.addContactsToList(this.contactList.id, contactIds).then(() => {

                this.$formulate.reset(this.formName);
                this.resetWorkingCopy();

                this.$emit("saved");
            }).finally(() => {
                this.saving = false;
            });
        },

        loadMostUsedContactLists() {
            return this.$axios.get("/api/contact_lists/most_used").then(response => {
                this.mostUsedContactLists = response.data["hydra:member"];
            });
        },

    }
}
