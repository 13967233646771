
import moment from "moment";
import ContactExcelImport from "./contact-excel-import";
export default {
    name: "ImportContactsModal",
    components: {ContactExcelImport},
    data(){
        return {
            importing:false,
            createContactList: false,
            uploaded: false
        }
    },
    watch:{

    },
    methods:{
        onUploaded(file){
            this.uploaded = !!file;
        },
        doImport(){
            this.importing = true;
            this.$refs.contactImport.submitForm().then((data)=>{
                this.$emit("imported");

                if(this.createContactList) {
                    const contactList = {
                        title: "Contact Import - " + this.formatDateTime(moment()),
                        description: "",
                        isPrivate: false,
                        creatingUser: `/api/users/${this.userInfo.id}`,
                        contactListContacts: data.map(x=>{ return {contact:`/api/contacts/${x.id}`}})
                    }

                    this.$axios.post("/api/contact_lists", contactList).finally(() => {
                        this.importing = false;
                    })
                }else{
                    this.importing = false;
                }


            }).catch(()=> {
                this.importing = false;
            })
        }
    }
}
