
import SaveButton from "../save-button.vue";


export default {
    name: "RedraftContractModal",
    components: {SaveButton},
    props: {
        contractToRedraft: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            newTitle: "",
            newEvent: null,
            loading: false,
        }
    },
    watch: {
        contractToRedraft: {
            handler (val) {
                this.newEvent = structuredClone(val.event);
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {

    },
    methods: {
        submitForm() {
            this.loading = true;
            this.$emit("redraft-proceed",
                       this.contractToRedraft.id,
                       this.newTitle,
                       this.newEvent
            );
        }
    }
}
