
import {mapActions} from "vuex";
import iriPreparation from "../../mixins/iri-preparation";
import LoadingSpinner from "../loading-spinner.vue";
import SaveButton from "../save-button.vue";
import {VISIBILITY_OPTIONS} from "../../mixins/contract-visibilities";
import PdfViewer from "../pdf-viewer.vue";

export default {
    name: "ContractTemplateModal",
    components: {PdfViewer, SaveButton, LoadingSpinner},
    mixins: [iriPreparation],
    props: {
        id: {
            type: String,
            default: "addContractModal"
        },
        event: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            loadingTemplateInfo: false,
            templates: [],
            selectedTemplates: [],
            templateInfo: {},
            signersByRole: {},
            submitting: false,
            visibility: 'public',
            VISIBILITY_OPTIONS,
            contractEvent: null,
            documentBase64: {},
            draft: true,
            activeTab: null,
            templateSelectionFinished: false
        }
    },
    computed: {},
    watch: {
        event: {
            handler() {
                this.contractEvent = this.event;
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.loadTemplates();
        });
    },
    methods: {
        ...mapActions({
            setPreventErrorHandling: "app/setPreventErrorHandling",
            setCurrentError: 'app/setCurrentError',
        }),
        loadTemplates() {
            this.loading = true;
            this.$axios.get('/api/contracts/templates').then((response) => {
                this.templates = response.data;
            })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadTemplateInfos() {
            this.loadingTemplateInfo = true;
            this.templateSelectionFinished = true;
            this.templateInfo = {};
            this.signersByRole = {};
            this.documentBase64 = {};

            this.setPreventErrorHandling(true);
            const templateRequests = this.selectedTemplates.map(template =>
                this.$axios.get('/api/contracts/templates/info/' + template.value)
            );

            Promise.all(templateRequests)
                .then(responses => {
                    responses.forEach((response, index) => {
                        const templateId = this.selectedTemplates[index].value;
                        this.templateInfo[templateId] = response.data;
                        this.documentBase64[templateId] = 'data:application/pdf;base64,' + response.data.documentData;
                        this.signersByRole[templateId] = {};
                    });
                    this.activeTab = Object.keys(this.templateInfo)[0];
                    this.loadingTemplateInfo = false;
                })
                .catch((error) => {
                    if (error.response && error.response.status && error.response.status === 400) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "warning",
                            title: "Could not send contract",
                            message: error.response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    } else {
                        this.setCurrentError(error);
                    }
                    this.templateSelectionFinished = false;
                })
                .finally(() => {
                    this.setPreventErrorHandling(false);
                    this.loadingTemplateInfo = false;
                });
        },
        submitForm() {
            if (this.draft) {
                if (!confirm("Are you sure you want to save this contract as a draft?")) {
                    return;
                }
            } else if (!confirm("Are you sure you want to send this contract?")) {
                return;
            }

            this.submitting = true;

            const finalSignersByRole = {};

            for (const [templateId, signers] of Object.entries(this.signersByRole)) {
                finalSignersByRole[templateId] = {};
                for (const [role, contact] of Object.entries(signers)) {
                    finalSignersByRole[templateId][role] = contact.id;
                }
            }

            this.setPreventErrorHandling(true);
            this.$axios.post('/api/contracts/templates/send', {
                templateIds: Object.keys(this.templateInfo),
                signersByRole: finalSignersByRole,
                visibility: this.visibility,
                draft: this.draft,
                event: this.contractEvent ? this.contractEvent.id : null
            }).then(() => {
                let messageTitle = "Contract Sent";
                let messageText = "The contract has been sent successfully.";
                if (this.draft) {
                    messageTitle = "Draft Saved";
                    messageText = "The contract has been saved as a draft.";
                }

                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: messageTitle,
                    message: messageText,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.$emit('contract-sent');
            })
                .catch((error) => {
                    if (error.response && error.response.status && error.response.status === 400) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "warning",
                            title: "Could not send contract",
                            message: error.response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    } else {
                        this.setCurrentError(error);
                    }
                })
                .finally(() => {
                    this.setPreventErrorHandling(false);
                    this.submitting = false;
                });
        },
        async onSignerInput(e) {
            this.loadingTemplateInfo = true;
            await this.$nextTick();
            this.loadingTemplateInfo = false;
        }
    }
}
