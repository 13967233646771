

import {TIER_OPTIONS} from "../../mixins/contact-tiers";

export default {
    name: "ContactTypeSelect",
    components: {},
    props: {
        value: {
            type: [Number],
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            TIER_OPTIONS,
        }
    },
    computed: {
        investorTier: {
            get() {
                if (this.value) {
                    return this.TIER_OPTIONS.find(tier => tier.value === this.value);
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$emit('input', newValue?.value);
            }
        },
    },
    watch: {},
}
