

import LoadingSpinner from "../loading-spinner";
import SaveButton from "../save-button";

export default {
    name: "UserContactBulkOperationModal",
    components: {
        LoadingSpinner,
        SaveButton,
    },
    props: {
        filters: {
            type: Object,
            default: () => {
                return {}
            }
        },
        contact: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            operation: "delete_from_contact_groups",
            bulkContactLists: null,
            loading: false,
            bulkOptions: [
                {value: 'delete_from_contact_groups', label: 'Delete Contact from Groups'},
            ],
            running: false,
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loadBulkContactLists();
        });
    },
    methods: {
        async loadBulkContactLists() {
            this.loading = true;
            const responseFilters = await this.$axios.post("/api/contact_lists/save_bulk_filters", {filters: this.filters});
            const hash = responseFilters.data.hash;

            this.$axios.get("/api/contact_lists/bulk_contact_lists", {
                params: {
                    hash
                }
            }).then(response => {
                this.bulkContactLists = response.data["hydra:member"];
            }).finally(() =>{
                this.loading = false;
            });
        },
        submitForm() {
            switch (this.operation) {
                case 'delete_from_contact_groups': {
                    if (!confirm('Do you really want to remove the contact from the groups?')) {
                        return;
                    }

                    this.running = true;
                    const contactListIds = this.bulkContactLists.map((contactList) => contactList.id);
                    this.$axios.post(`/api/contact_lists/remove_contact/${this.contact.id}`, {
                        contactListIds
                    },).then(response => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: 'success',
                            title: 'Deleted successfully',
                            message: `contact has been successfully deleted from ${this.pluralize(this.bulkContactLists.length, 'contact group')}`,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }
            }
        },
    }
}
