
import moment from "moment";

export default {
    name: "EmailDropdown",
    props: {
        contacts: {
            type: Array,
            required: true,
        },
        showCopyIcon: {
            type: Boolean,
            default: true,
        },
        showAtIcon: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        emailAddresses() {
            return this.contacts.filter(contact => contact.email).map(contact => contact.email);
        },
        contactIds() {
            return this.contacts.filter(contact => contact.id).map(contact => contact.id);
        },
        emailBase64() {
            return btoa(this.emailAddresses.join(','));
        },
        sendEmailToContacts() {
            return this.contactIds.length > 0 && this.contactIds.length === this.emailAddresses.length;
        },
        isEmailAvailable() {
            return this.emailAddresses && this.emailAddresses.length > 0;
        },
    },
    mounted() {
        this.buildEvents()
    },
    beforeDestroy() {
        if (this.$refs["email-dropdown"]) {
            this.$refs["email-dropdown"].removeEventListener("show.bs.dropdown", this.emitOpen);
            this.$refs["email-dropdown"].removeEventListener("hide.bs.dropdown", this.emitClose);
        }
    },
    methods: {
        async copyEmail() {
            try {
                await navigator.clipboard.writeText(this.emailAddresses.join(';'));
                const toastId = this.generateUUID();
                await this.addToast({
                    type: "success",
                    title: "Success",
                    message: this.pluralize(this.emailAddresses.length, 'Email address has', 'Email addresses have') + " been copied",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            } catch ($e) {
                alert('Cannot copy');
            }
        },
        resetPopover() {
            this.contactHovered = moment().unix()
            this.$nextTick(() => {
                this.buildEvents();
            })
        },
        buildEvents() {
            if ("email-dropdown" in this.$refs) {
                this.$refs["email-dropdown"].addEventListener("show.bs.dropdown", this.emitOpen);
                this.$refs["email-dropdown"].addEventListener("hide.bs.dropdown", this.emitClose);
            }
        },
        emitClose() {
            this.$emit("popover-closed");

        },
        emitOpen() {
            console.log("ASDASDASDD");
            this.$emit("popover-opened");
        },
    }
}
