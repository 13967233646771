
import ContactGoogleIcon from "./helpers/contact-google-icon.vue";

export default {
    name: "DuplicatesTable",
    components: {ContactGoogleIcon},
    data() {
        return {
            config: {
                columns: [
                    {
                        headline: "Action",
                    },
                    {
                        headline: "Name",
                    },
                    {
                        headline: "Links",
                    },
                    {
                        headline: "Email",
                    },
                    {
                        headline: "Personal Email",
                    },
                    {
                        headline: "Company",
                    },
                ],
                ajaxUrl: "/api/contacts/duplicates/list",
                pagination: 50,
                search: true,
            },
            mergeCouplesDict: {},
            continueCouplesDict: {},
            couplesToIgnoreDict: {},
            keepContact: {},
            continuingContact: {},
            continueTargetDate: {},
            deduplicationKey: this.generateUUID(),
            processing: false,
        }
    },
    computed: {
        mergeCouples() {
            return Object.keys(this.mergeCouplesDict).filter(x => this.mergeCouplesDict[x]);
        },
        continueCouples() {
            return Object.keys(this.continueCouplesDict).filter(x => this.continueCouplesDict[x]);
        },
        couplesToIgnore() {
            return Object.keys(this.couplesToIgnoreDict).filter(x => this.couplesToIgnoreDict[x]);
        },
        selectedRows() {
            return this.mergeCouples.length + this.continueCouples.length + this.couplesToIgnore.length;
        }
    },
    methods: {
        process() {
            this.processing = true;
            this.$axios.post("/api/contacts/duplicates/process", {
                mergeCouples: this.mergeCouplesDict,
                couplesToIgnore: this.couplesToIgnore,
                keepContact: this.keepContact,
                continueCouples: this.continueCouplesDict,
                continuingContact: this.continuingContact,
                continueTargetDate: this.continueTargetDate,
            })
                .then((response) => {
                    const toastId = this.generateUUID();
                    const sentences = [];

                    sentences.push(`${response.data.mergeCount} contact(s) merged.`);
                    if (response.data.mergeFailedCount > 0) {
                        sentences.push(`Merging of ${response.data.mergeFailedCount} contact(s) failed.`);
                    }

                    sentences.push(`${response.data.continueCount} continue relationship(s) added.`);
                    if (response.data.continueFailedCount > 0) {
                        sentences.push(`Adding ${response.data.continueFailedCount} continue relationship(s) failed.`);
                    }

                    sentences.push(`${response.data.couplesToIgnoreAddedCount} pairs marked to be ignored.`);

                    let type = "success";
                    if (response.data.mergeFailedCount + response.data.continueFailedCount > 0) {
                        if ((response.data.mergeCount + response.data.couplesToIgnoreAddedCount) === 0) {
                            // error somehow does not work, only warning
                            type = "warning";
                        } else {
                            type = "warning";
                        }
                    }

                    this.addToast({
                        type,
                        title: "Contacts processed",
                        message: `${sentences.join(" ")}`,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.processing = false;
                    this.reset();
                });
        },
        reset() {
            this.mergeCouplesDict = {};
            this.couplesToIgnoreDict = {};
            this.keepContact = {};
            this.continueCouplesDict = {};
            this.continuingContact = {};
            this.continueTargetDate = {};
            this.deduplicationKey = this.generateUUID();
        },
        getCircleCharacter(floatValue) {
            const colors = ["\u{1F534}", "\u{1F7E0}", "\u{1F7E1}", "\u{1F7E2}"];
            const colorIndex = Math.min(Math.floor(floatValue * 4), 3);
            return colors[colorIndex];
        },
        getColorClass(floatValue) {
            const colorClasses = ["red", "orange", "yellow", "green"];
            const colorIndex = Math.min(Math.floor(floatValue * 4), 3);
            return colorClasses[colorIndex];
        },
    }
}
