


export default {
    name: "OfficeContactsModal",
    props: {
        id: {
            type: String,
            default: "officeContactsModal"
        },

    },
    data() {
        return {
            officeContacts: [],
            isTableLoading: false,
            tableConfig: {
                columns: [
                    {
                        headline: "First Name",
                        sort: true
                    },
                    {
                        headline: "Middle Name",
                    },
                    {
                        headline: "Last Name",
                    },
                    {
                        headline: "Nickname",
                        breakpoint: 'all',
                    },
                    {
                        headline: "Initials",
                        breakpoint: 'all',
                    },
                    {
                        headline: "Birthday",
                        breakpoint: 'all',
                    },
                    {
                        headline: "Title",
                        breakpoint: 'all',
                    },
                    {
                        headline: "Job Title",
                        breakpoint: 'all',
                    },
                    {
                        headline: "Company",
                    },
                    {
                        headline: "Department",
                        breakpoint: 'all',
                    },
                    {
                        headline: "Email",
                        breakpoint: 'all',
                    },
                    {
                        headline: "Phone",
                        breakpoint: 'all',
                    },
                    {
                        headline: "Address",
                        breakpoint: 'all',
                    },
                    {
                        headline: "Actions",
                    }
                ],
                pagination: 10,
                ajaxUrl: "/api/graph/contact/list",
                search: true,
            },
            showTable: false,
        }
    },
    computed: {
        rows() {
            return this.officeContacts.map((row) => {
                return [
                    {
                        text: row.givenName,
                    },
                    {
                        text: row.middleName,
                    },
                    {
                        text: row.surname,
                    },
                    {
                        text: row.nickName,
                    },
                    {
                        text: row.initials,
                    },
                    {
                        text: row.birthday,
                    },
                    {
                        text: row.title,
                    },
                    {
                        text: row.jobTitle,
                    },
                    {
                        text: row.companyName,
                    },
                    {
                        text: row.department,
                    },
                    {
                        text: (row.emailAddresses && row.emailAddresses.length > 0) ? row.emailAddresses[0].address : "",
                    },
                    {
                        text: (row.phones && row.phones.length > 0) ? row.phones[0].phone : "",
                    },
                    {
                        text: (row.postalAddresses && row.postalAddresses.length > 0) ? row.postalAddresses[0].address : "",
                    },
                    {
                        type: 'actions',
                        officeContactId: row.id,
                    }
                ];
            });
        }
    },
    watch: {

    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.showTable = true;
            // Load Contacts...
            // this.loadOfficeContacts();
        })
    },
    methods: {
        loadOfficeContacts() {
            this.isTableLoading = true;
            this.$axios.get('/api/graph/contact/list')
                .then((response) => {
                    this.officeContacts = response.data;
                })
                .finally(() => {
                    this.isTableLoading = false;
                });
        },
        importOfficeContact(graphId) {
            this.$axios.post('/api/graph/contact/import/' + graphId)
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        htmlMessage: this.escapeHtml(response.data.message) + "<br/>Click this message to open the contact profile.",
                        onClicked: () => {
                            this.$router.push('/contacts/' + response.data.contactId);
                        },
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                });
        }
    }
}
