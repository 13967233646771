

export default {
    name: "ContactTypeSelect",
    components: {},
    props: {
        required: {
            type: Boolean,
        },
        value: {
            type: [Object, String, Number],
            required: false,
            default: undefined,
        },
        reduce: {
            type: Function,
            required: false,
            default: x => x['@id'],
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Type',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select Type...',
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            contactTypeOptions: [],
            selectedVariant: this.value,
        }
    },
    computed: {},
    watch: {
        selectedVariant: {
            handler(val) {
                this.$emit("input", val)
            },
        },
    },
    mounted() {
        this.loadContactTypeOptions();
    },
    created() {
    },
    methods: {
        async loadContactTypeOptions() {
            const response = await this.$axios.get("/api/contact_types")

            this.contactTypeOptions = response.data['hydra:member'];
        },
    }
}
