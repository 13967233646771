import { render, staticRenderFns } from "./signer-form.vue?vue&type=template&id=1ab3cb3a&scoped=true"
import script from "./signer-form.vue?vue&type=script&lang=js"
export * from "./signer-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab3cb3a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PdfViewer: require('/codebuild/output/src214552897/src/web/components/pdf-viewer.vue').default,SaveButton: require('/codebuild/output/src214552897/src/web/components/save-button.vue').default})
