

import moment from "moment/moment";
import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";

export default {
    name: "ContactGroupBulkOperationModal",
    components: {
        LoadingSpinner,
        SaveButton,
    },
    props: {
        filters: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            rowsUpdated: moment().unix(),
            operation: "delete_contact_groups",
            bulkContactLists: [],
            loading: true,
            comparisonComplete: false,

            selectedIntersectionContacts: [],
            selectedOnlyIn1Contacts: [],
            selectedOnlyIn2Contacts: [],

            preSelectedIntersection: [],
            preSelectedIntersectionIds: [],
            preSelectedOnlyIn1ContactIds: [],
            preSelectedOnlyIn1Contact: [],
            preSelectedOnlyIn2Contact: [],
            preSelectedOnlyIn2ContactIds: [],

            ajaxRows1: [],
            ajaxRows2: [],
            ajaxRows3: [],
            allItem1 : null,
            allItem2 : null,
            allItem3 : null,
            pageChanged1: false,
            pageChanged2: false,
            pageChanged3: false,
            running: false,

        }
    },
    computed: {
        bulkOptions() {
            const operations = [
                {value: 'delete_contact_groups', label: 'Delete Contact Groups'},
            ];
            if (this.bulkContactLists.length === 2) {
                operations.push(  {value: 'compare', label: 'Compare Contact Groups'})
            }
            return operations;
        },
        selectedRows() {
            const preSelectedIds = this.preSelectedIntersection.concat(this.preSelectedOnlyIn1Contact).concat(this.preSelectedOnlyIn2Contact).map(row => {
                return {
                    id: row.value
                }
            })

            return this.selectedIntersectionContacts.concat(this.selectedOnlyIn1Contacts).concat(this.selectedOnlyIn2Contacts)
                .concat(preSelectedIds);
        },
        config1() {
            return {
                columns: [
                    {
                        headline: "Name",
                        sort: true
                    },
                    {
                        headline: "Company",
                        sort: true
                    },
                ],
                ajaxUrl: "/api/contact_lists/compare/" + this.bulkContactLists[0].id + "/" + this.bulkContactLists[1].id + "?mode=intersection",
                pagination: 10,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
                selectAll: true,
            };
        },
        config2() {
            return {
                columns: [
                    {
                        headline: "Name",
                        sort: true
                    },
                    {
                        headline: "Company",
                        sort: true
                    },
                ],
                ajaxUrl: "/api/contact_lists/compare/" + this.bulkContactLists[0].id + "/" + this.bulkContactLists[1].id+ "?mode=onlyIn1",
                pagination: 10,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
                selectAll: true,
            };
        },
        config3() {
            return {
                columns: [
                    {
                        headline: "Name",
                        sort: true
                    },
                    {
                        headline: "Company",
                        sort: true
                    },
                ],
                ajaxUrl: "/api/contact_lists/compare/" + this.bulkContactLists[0].id + "/" + this.bulkContactLists[1].id + "?mode=onlyIn2",
                pagination: 10,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
                selectAll: true,
            };
        },
        // if filters for table are needed
        filters1: {
            async set(val) {},
            get() {
                return {}
            }
        },
        filters2: {
            async set(val) {},
            get() {
                return {}
            }
        },
        filters3: {
            async set(val) {},
            get() {
                return {}
            }
        }
    },
    watch: {
        filters() {
            this.comparisonComplete = false;
            this.loadBulkContactLists();
        },
        operation() {
            this.comparisonComplete = false;
            this.selectedIntersectionContacts=[];
            this.selectedOnlyIn1Contacts=[];
            this.selectedOnlyIn2Contacts=[];

            this.preSelectedIntersection=[];
            this.preSelectedIntersectionIds=[];
            this.preSelectedOnlyIn1ContactIds=[];
            this.preSelectedOnlyIn1Contact=[];
            this.preSelectedOnlyIn2Contact=[];
            this.preSelectedOnlyIn2ContactIds=[];

            this.ajaxRows1=[];
            this.ajaxRows2=[];
            this.ajaxRows3=[];
            this.allItem1 =null;
            this.allItem2 =null;
            this.allItem3 =null;
            this.pageChanged1=false;
            this.pageChanged2=false;
            this.pageChanged3=false;
        },
        selectedIntersectionContacts(rows) {
            if (!this.pageChanged1 && this.ajaxRows1) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedIntersectionIds.includes(rows[i].id)) {
                        this.preSelectedIntersectionIds.push(rows[i].id);
                    }
                }
                for (let j = 0; j < this.ajaxRows1.length; j++) {
                    const id = this.ajaxRows1[j].id;
                    const index = this.preSelectedIntersectionIds.indexOf(id);
                    if (!rows.map(r => r.id).includes(id) && index !== -1) {
                        this.preSelectedIntersectionIds.splice(index, 1);
                    }
                }
            }
        },
        selectedOnlyIn1Contacts(rows) {
            if (!this.pageChanged2 && this.ajaxRows2) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedOnlyIn1ContactIds.includes(rows[i].id)) {
                        this.preSelectedOnlyIn1ContactIds.push(rows[i].id);
                    }
                }
                for (let j = 0; j < this.ajaxRows2.length; j++) {
                    const id = this.ajaxRows2[j].id;
                    const index = this.preSelectedOnlyIn1ContactIds.indexOf(id);
                    if (!rows.map(r => r.id).includes(id) && index !== -1) {
                        this.preSelectedOnlyIn1ContactIds.splice(index, 1);
                    }
                }
            }
        },
        selectedOnlyIn2Contacts(rows) {
            if (!this.pageChanged3 && this.ajaxRows3) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedOnlyIn2ContactIds.includes(rows[i].id)) {
                        this.preSelectedOnlyIn2ContactIds.push(rows[i].id);
                    }
                }
                for (let j = 0; j < this.ajaxRows3.length; j++) {
                    const id = this.ajaxRows3[j].id;
                    const index = this.preSelectedOnlyIn2ContactIds.indexOf(id);
                    if (!rows.map(r => r.id).includes(id) && index !== -1) {
                        this.preSelectedOnlyIn2ContactIds.splice(index, 1);
                    }
                }
            }
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.loadBulkContactLists();
        });
    },
    methods: {
        // Hash of bulk operation is currently not pushed to store!
        /* ...mapActions({
            setBulkContactGroupFiltersHash: "app/setBulkContactGroupFiltersHash",
        }), */

        submitForm() {
            switch (this.operation) {
                case "compare": {
                    this.comparisonComplete = true;
                    break;
                }
                case 'delete_contact_groups': {
                    const contactListIds = this.bulkContactLists.map(c => c.id);
                    if (!confirm(`Are you sure you want to delete ${contactListIds.length} Contact Groups? These Contact Groups will be deleted for everyone. This cannot be undone.`)) {
                        return;
                    }

                    this.running = true;
                    this.$axios.post(`/api/contact_lists/bulk_delete`, {contactListIds}).then(response => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: 'success',
                            title: 'Deleted successfully',
                            message: `${response.data.count} Contact Groups(s) have been successfully deleted`,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }
            }
        },
        async loadBulkContactLists() {
            this.loading = true;
            const responseFilters = await this.$axios.post("/api/contact_lists/save_bulk_filters", {filters: this.filters});
            const hash = responseFilters.data.hash;

            this.$axios.get("/api/contact_lists/bulk_contact_lists", {
                params: {
                    hash
                }
            }).then(response => {
                this.bulkContactLists = response.data["hydra:member"];
            }).finally(() =>{
                this.loading = false;
            });
        },
        cancel() {
            this.selectedIntersectionContacts = this.selectedOnlyIn1Contacts = this.selectedOnlyIn2Contacts = []
            this.comparisonComplete = false;
        },
        openContactBulk(mode, id) {
            let filter
            switch (mode) {
                case "selected":
                    filter = {
                        ids: this.selectedRows.map((row) => {
                            return row.id
                        })
                    }
                    break;
                case "contactGroup":
                    filter = {
                        contactGroupIds: [id]
                    }
                    break;
                case "intersection":
                    filter = {
                        intersection: [this.bulkContactLists[0].id, this.bulkContactLists[1].id]
                    }
            }

            this.$emit('contact-bulk', filter)
            this.cancel();
        },
        openContactBulkWithDifference(minuendId, subtrahendId) {
            this.$axios.post("/api/contact_lists/difference/" + minuendId + "/" + subtrahendId ).then(response => {
                this.$emit('contact-bulk', {
                    hash: response.data.hash
                })
            });
        },
        onPageChange1() {
            this.pageChanged1 = true;
        },
        onPageChange2() {
            this.pageChanged2 = true;
        },
        onPageChange3() {
            this.pageChanged3 = true;
        },
        onRowsUpdated1(data) {
            this.pageChanged1 = false;
            if (data && data.rows && data.rows.length) {
                this.$nextTick(() => {
                    this.preSelectedIntersection = JSON.parse(JSON.stringify(this.preSelectedIntersectionIds)).map(id => {
                        return {
                            key: "id",
                            value: id
                        }
                    });
                });
            }

            this.ajaxRows1 = data.rows;
            this.allItem1 = data.all;
        },
        onRowsUpdated2(data) {
            this.pageChanged2 = false;
            if (data && data.rows && data.rows.length) {
                this.$nextTick(() => {
                    this.preSelectedOnlyIn1Contact = JSON.parse(JSON.stringify(this.preSelectedOnlyIn1ContactIds)).map(id => {
                        return {
                            key: "id",
                            value: id
                        }
                    });
                });
            }
            this.ajaxRows2 = data.rows;
            this.allItem2 = data.all;
        },
        onRowsUpdated3(data) {
            this.pageChanged3 = false;
            if (data && data.rows && data.rows.length) {
                this.$nextTick(() => {
                    this.preSelectedOnlyIn2Contact = JSON.parse(JSON.stringify(this.preSelectedOnlyIn2ContactIds)).map(id => {
                        return {
                            key: "id",
                            value: id
                        }
                    });
                });
            }
            this.ajaxRows3 = data.rows;
            this.allItem3 = data.all;
        }
    }
}
