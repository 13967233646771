

import PdfViewer from "../pdf-viewer.vue";
import iriPreparation from "../../mixins/iri-preparation";

export default {
    name: "SignerForm",
    components: {
        PdfViewer
    },
    mixins: [iriPreparation],
    props: {
        contract: {
            type: Object,
            required: true,
        },
        contractRecipientToEdit: {
            type: Object,
            required: true,
        },
        documentUrl: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            contractRecipientWorkingCopy: null,
            position: null,
            loading: false,
        }
    },
    computed: {},
    watch: {
        contractRecipientToEdit: {
            handler(val) {
                this.contractRecipientWorkingCopy = structuredClone(val);
                if (val.contractSignatureTabs && val.contractSignatureTabs.length > 0) {
                    const signatureTab = val.contractSignatureTabs[0];
                    this.position = {
                        page: signatureTab.pageNumber,
                        coordinates: {
                            x: signatureTab.positionX,
                            y: signatureTab.positionY
                        }
                    };
                }
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
    },
    methods: {
        updatePosition(position) {
            this.position = position;
        },
        submitSigner() {
            if (!this.contractRecipientWorkingCopy.contact || !this.position) {
                alert("Please select a signer and a position");
                return;
            }
            this.loading = true;

            const signer = this.prepareIri(this.contractRecipientWorkingCopy.contact);
            const position = this.position;
            const requestInitials = this.contractRecipientWorkingCopy.requestInitials != null ? Number.parseInt(this.contractRecipientWorkingCopy.requestInitials) : null;

            this.$axios.post(`/api/contracts/${this.contract.id}/signer`, {
                signer,
                position,
                requestInitials
            })
                .then((response) => {
                    this.$emit("update", response.data);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        removeRecipient() {
            if(!confirm("Are you sure you want to remove this recipient?")) {
                return;
            }

            if(String(this.contractRecipientWorkingCopy.id).includes('-')) {
                // ID is  a UUID -> not persisted yet, we cannot delete it.
                return;
            }

            this.$axios.delete(`/api/contract_recipients/${this.contractRecipientWorkingCopy.id}`)
                .then(() => {
                    this.$emit("remove", this.contractRecipientWorkingCopy);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        removeUnsavedRecipient() {
            if(!confirm("Are you sure you want to remove this recipient?")) {
                return;
            }

            this.$emit("removeUnsaved", this.contractRecipientWorkingCopy);
        },
        checkForUnsavedChanges() {
            if(this.contractRecipientToEdit.contact && this.contractRecipientToEdit.contact.id) {
                // We have a contact, so we can check for unsaved changes
                if(this.contractRecipientToEdit.contact.id !== this.contractRecipientWorkingCopy.contact.id) {
                    return true;
                }
            }

            if(this.contractRecipientToEdit.requestInitials !== this.contractRecipientWorkingCopy.requestInitials) {
                return true;
            }

            if(this.contractRecipientToEdit.contractSignatureTabs.length !== this.contractRecipientWorkingCopy.contractSignatureTabs.length) {
                return true;
            }

            // Make array out of signature tabs which is an object
            const signatureTabs = Object.values(this.contractRecipientToEdit.contractSignatureTabs);

            if(signatureTabs.length > 0) {
                const signatureTab = signatureTabs[0];
                if(signatureTab.pageNumber !== this.position.page) {
                    return true;
                }
                if(signatureTab.positionX !== parseInt(this.position.coordinates.x)) {
                    return true;
                }
                if(signatureTab.positionY !== parseInt(this.position.coordinates.y)) {
                    return true;
                }
            }

            return false;
        }
    }
}
