
import moment from "moment";
import LoadingSpinner from '../loading-spinner'
import SearchSelect from "../search-select";
import iriPreparation from '~/mixins/iri-preparation'

export default {
    name: "ContactDeleteModal",
    components: {LoadingSpinner, SearchSelect},
    mixins: [iriPreparation],
    props: {
        title: {
            type: String,
            default: "Confirm",
        },
        itemId: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: "danger",
            validator: function validator(value) {
                return ["danger", "info", "success", "primary"].includes(value);
            }
        },
        cancelText: {
            type: String,
            default: "Cancel"
        }
    },
    data() {
        return {
            contact: null,
            isLoading: true,
            confirmed: false,
            uuid: this.generateUUID(),
            emailError: null,
            selectedContact: null,
            usages: {
                numberContactListContacts: null,
                numberRelationships: null,
                numberContactManagesTransactions: null,
                numberNotes: null,
                numberInteractionParticipants: null,
                numberEventParticipations: null,
                numberEmails: null,
                numberEmailRecipients: null,
                numberAssistanceContacts: null,
                numberUsers: null,
                canBeDeleted: null,
                numberStaffedUsers: null,
            },
            action: 'inactive',
            inactivePermanently: false,
            reason: '',
        }
    },
    computed: {
        forSelectAjaxUrl() {
            const params = {};
            if (this.itemId !== null) {
                params.exclude = this.itemId;
            }
            return "/api/contacts/for_select?" + this.buildQueryString(params)
        },
        hasToBeReplaced() {
            return this.usages.numberInteractionParticipants ||
                this.usages.numberAssistanceContacts ||
                this.usages.numberUsers ||
                this.usages.numberStaffedUsers;
        },
        canBeDeletedUsages() {
            if (!this.usages) {
                return [];
            }

            const usageReasons = [];
            if (this.usages.numberRelationships) {
                usageReasons.push(this.pluralize(this.usages.numberRelationships, 'relationship'));
            }
            if (this.usages.numberContactListContacts) {
                usageReasons.push(this.pluralize(this.usages.numberContactListContacts, 'contact group'));
            }
            if (this.usages.numberEmails) {
                usageReasons.push(this.pluralize(this.usages.numberEmails, 'email'));
            }
            if (this.usages.numberEmailRecipients) {
                usageReasons.push(this.pluralize(this.usages.numberEmailRecipients, 'email recipient'));
            }
            if (this.usages.numberNotes) {
                usageReasons.push(this.pluralize(this.usages.numberNotes, 'note'));
            }
            if (this.usages.numberContactManagesTransactions) {
                usageReasons.push(this.pluralize(this.usages.numberContactManagesTransactions, 'transaction'));
            }
            if (this.usages.numberEventParticipations) {
                usageReasons.push(this.pluralize(this.usages.numberEventParticipations, 'event'));
            }

            return usageReasons;
        },
        hasToBeReplacedUsages() {
            if (!this.usages) {
                return [];
            }

            const usageReasons = [];
            if (this.usages.numberInteractionParticipants) {
                usageReasons.push(this.pluralize(this.usages.numberInteractionParticipants, 'interaction'));
            }
            if (this.usages.numberAssistanceContacts) {
                usageReasons.push(this.pluralize(this.usages.numberAssistanceContacts, 'assistant'));
            }
            if (this.usages.numberUsers) {
                usageReasons.push(this.pluralize(this.usages.numberUsers, 'user'));
            }
            if (this.usages.numberCallLogs) {
                usageReasons.push(this.pluralize(this.usages.numberCallLogs, 'call log'));
            }
            if (this.usages.numberStaffedUsers) {
                usageReasons.push(this.pluralize(this.usages.numberStaffedUsers, 'staffed user'));
            }
            return usageReasons;
        },
        temporaryOrInactiveText() {
            if (!this.contact) {
                return null;
            }

            if (!this.contact.isActive && this.contact.isTemporary) {
                return null;
            }

            const updateOptions = [];
            if (this.contact.isActive) {
                updateOptions.push("as inactive");
            }
            if (!this.contact.isTemporary) {
                updateOptions.push("as temporary");
            }

            return `Alternatively, you can also set the contact ${this.formatArray(updateOptions, 'or')}.`
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.isLoading = true;
            const promises = [
                this.findUsages(),
                this.loadContact(),
            ];

            Promise.all(promises).then(() => {
                this.isLoading = false;
            });
        });

        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            if (!this.confirmed) {
                this.$emit('canceled', this.itemId);
            }
            this.confirmed = false;
        });
    },
    methods: {
        setTemporary() {
            const finalContact = this.prepareIri(this.contact);
            finalContact.isTemporary = true;
            finalContact.inactiveReason = this.reason;
            this.$axios.patch(`/api/contacts/${finalContact.id}`, finalContact, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Success",
                    message: "Contact set temporary",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.$emit('updated', finalContact.id);
            });
        },
        setInactive() {
            const finalContact = this.prepareIri(this.contact);

            let newInactiveReason = ((new Date()).toISOString() + ": " +  this.reason);
            if(finalContact.inactiveReason) {
                newInactiveReason = finalContact.inactiveReason + "\n" + newInactiveReason;
            }
            finalContact.inactiveReason = newInactiveReason.trim();
            finalContact.inactivePermanently = this.inactivePermanently;
            finalContact.activeTo = moment().subtract(1, "days").format("YYYY-MM-DD");

            this.$axios.patch(`/api/contacts/${finalContact.id}`, finalContact, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Success",
                    message: "Contact was set inactive",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.$emit('updated', finalContact.id);
            });
        },
        /*
        deleteContact() {
            this.$axios.delete(`/api/contacts/${this.itemId}`).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Success",
                    message: "Contact deleted",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.$emit('deleted', this.itemId);
            })
        }, */
        replaceThisContact() {
            const contactId = this.itemId;
            if (this.selectedContact) {
                this.$axios.post(`/api/contacts/usages/replace/${contactId}/${this.selectedContact.id}`)
                    .then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contact Replaced",
                            message: `All usages of contact #${contactId} is replaced with the contact #${this.selectedContact.id}`,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                            this.$emit('replaced', this.selectedContact.id);
                        });
                    })
                    .finally(() => {
                        this.selectedContact = null;
                    });
            }
        },
        loadContact() {
            return this.$axios.get(`/api/contacts/${this.itemId}`).then(response => {
                this.contact = response.data;
                this.inactivePermanently = this.contact.inactivePermanently;
            });
        },
        findUsages() {
            return this.$axios.get(`/api/contacts/usages/${this.itemId}`).then(response => {
                this.usages = response.data;
            });
        },
        submitForm() {
            switch (this.action) {
                case 'temporary':
                    this.setTemporary();
                    break;
                case 'inactive':
                    this.setInactive();
                    break;
                case 'replace':
                    this.replaceThisContact();
                    break;
            }
        }
    },
}
