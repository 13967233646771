

import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";
import iriPreparation from "~/mixins/iri-preparation";

export default {
    name: "EditPersonalInformationModal",
    components: {LoadingSpinner, SaveButton},
    mixins: [iriPreparation],
    props: {
        id: {
            type: String,
            default: "editPersonalInformationModal"
        },
        contact: {
            type: Object,
            required: true
        },
        personalInformationToEdit: {
            type: Object,
            default: null,
        },
        isLoading: {
            type: Boolean
        },
    },
    data() {
        return {
            categoryTemplate: null,
            category: null,
            contents: "",
            loaded: false,
            relation: "",
            name: "",
            birthdayProperty: null,
            saving: false,
            personalInformationToEditCopy: "",
            allCategories: null,
        }
    },
    computed: {
        isFamily() {
            if (this.category && this.category.title) {
                return this.category.title.toLowerCase() === "family";
            }
            return false;
        },
        isNew() {
            return !this.personalInformationToEdit || !this.personalInformationToEdit.id
        },
        birthday: {
            get() {
                if (this.birthdayProperty) {
                    const date = new Date(this.birthdayProperty);
                    const day = ("0" + date.getDate()).slice(-2);
                    const month = ("0" + (date.getMonth() + 1)).slice(-2);
                    const year = date.getFullYear();
                    return this.padWithZeros(year, 4) + "-" + (month) + "-" + (day);
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.birthdayProperty = newValue || null;
            }
        },
    },

    mounted() {
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            this.$emit('closed', this.item)
        });
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loadCategoryTemplate();
            this.loadCategories();
        });
    },
    created() {

        if (!this.isNew) {
            this.personalInformationToEditCopy = JSON.parse(JSON.stringify(this.personalInformationToEdit));
            this.category = this.personalInformationToEdit.contactPersonalInformationCategory;

            this.$nextTick(() => {
                if (this.isFamily) {
                    const previousInformation = this.personalInformationToEdit.value.split(':');
                    if (previousInformation[0] !== "") {
                        this.relation = previousInformation[0];
                    }
                    if (previousInformation[1] !== "") {
                        this.name = previousInformation[1];
                    }
                    if (previousInformation[2] !== "") {
                        this.birthdayProperty = previousInformation[2];
                    }
                } else {
                    this.contents = this.personalInformationToEdit.value;
                }
            })


        }

    },
    methods: {
        loadCategories() {
            this.$axios.get("/api/contact_personal_information_categories/most_used").then(response => {
                this.allCategories = response.data["hydra:member"];
            })
        },
        loadCategoryTemplate() {
            this.$axios.get('/api/contact_personal_information_categories/empty')
                .then((response) => {
                    this.categoryTemplate = response.data;
                });
        },
        addPersonalInformation() {
            this.$emit("update-start");
            this.saving = true;
            let finalCategory = {};

            if (this.category["search-select-created-option"]) {
                finalCategory = JSON.parse(JSON.stringify(this.categoryTemplate));
                finalCategory.title = this.category.title;
            } else {
                finalCategory = this.category["@id"];
            }

            let newPersonalInformation = {};
            if (!this.isNew) {
                newPersonalInformation = this.personalInformationToEdit;
            }

            newPersonalInformation.contactPersonalInformationCategory = finalCategory;
            if (this.isFamily) {
                newPersonalInformation.value = this.relation + ":" + this.name + ":" + this.birthdayProperty;
            } else {
                newPersonalInformation.value = this.contents;
            }
            newPersonalInformation.contact = this.contact;

            // Create a copy, replace objects by IRIs
            const finalPersonalInformation = this.prepareIri(newPersonalInformation);

            if (!this.isNew) {
                this.$axios.patch('/api/contact_personal_informations/' + finalPersonalInformation.id, finalPersonalInformation, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then((response) => {
                    this.$emit('contact-personal-information-updated');
                }).finally(() => {
                    this.saving = false;
                });
            } else {
                this.$axios.post('/api/contact_personal_informations', finalPersonalInformation).then((response) => {
                    this.$emit('contact-personal-information-updated');
                }).finally(() => {
                    this.saving = false;
                });
            }
        },
        contactPersonalInformationUpdated() {
            this.$emit('contact-personal-information-updated');

        },
        updateStart() {
            this.$emit('update-start');
        },
    }
}
