
export default {
    name: "ConfirmModal",
    props: {
        title: {
            type: String,
            default: "Confirm",
        },
        body: {
            type: String,
            default: "Do you want to continue?"
        },
        item: {
            type: [Object, Array, String, Number],
            default: null,
        },
        type: {
            type: String,
            default: "danger",
            validator: function validator(value) {
                return ["danger", "info", "success", "primary"].includes(value);
            }
        },
        confirmText: {
            type: String,
            default: "Yes"
        },
        cancelText: {
            type: String,
            default: "No"
        }
    },
    data() {
        return {
            confirmed: false,
            uuid: this.generateUUID()
        }
    },
    mounted() {
        this.$refs.modalConfirm.addEventListener('hidden.bs.modal', () => {
            if (!this.confirmed) {
                this.$emit('canceled', this.item)
            }
            this.confirmed = false;
        })
    },
    methods: {
        confirm() {
            this.confirmed = true;
            this.$emit('confirmed', this.item);
        },
    }
}
