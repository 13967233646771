
import iriPreparation from "../../mixins/iri-preparation";
import PdfViewer from "../pdf-viewer.vue";
import {VISIBILITY_OPTIONS} from "../../mixins/contract-visibilities";
import SignerForm from "./signer-form.vue";

export default {
    name: "EditContractModal",
    components: {PdfViewer, SignerForm},
    mixins: [iriPreparation],
    props: {
        id: {
            type: String,
            default: "addContractModal"
        },
        contractToEdit: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            contractWorkingCopy: null,
            forceActiveTabNumber: null,
            contractFormKey: 'contractFormKey',
            unsavedChanges: [],
            currentDocumentUrl: null,
            VISIBILITY_OPTIONS
        }
    },
    computed: {},
    watch: {
        contractToEdit: {
            handler(val) {
                this.updateContractWorkingCopy(val)
            },
            deep: true,
            immediate: true
        },
        contractWorkingCopy: {
            handler(val) {
                // Document Url for previews
                if (val && val.documentUrl) {
                    this.currentDocumentUrl = val.documentUrl;
                } else {
                    this.currentDocumentUrl = null;
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
    },
    methods: {
        submitForm() {
            if (!confirm("Are you sure you want to save this contract? Please note that the information entered cannot be altered at a later stage.")) {
                return;
            }

            let contractData = structuredClone(this.contractWorkingCopy);
            delete contractData.document;
            contractData = this.prepareIri(contractData);

            this.loading = true;

            if (this.contractWorkingCopy.id) {
                this.$axios.patch(`/api/contracts/${this.contractWorkingCopy.id}`, contractData, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                    .then((response) => {
                        this.contractWorkingCopy = response.data;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            } else {
                this.$axios.post('/api/contracts', contractData)
                    .then(async (response) => {
                        this.contractWorkingCopy = response.data;
                        await this.$nextTick();
                        this.addNewSigner();
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        sendContract(asDraft = false) {
            let message = "Are you sure you want to send this contract?";
            if (asDraft) {
                message = "Are you sure you want to create a draft of this contract in Docusign? Please note that the information entered cannot be altered at a later stage.";
            }
            if (!confirm(message)) {
                this.loading = false;
                return;
            }

            this.loading = true;
            this.$axios.post(`/api/contracts/${this.contractWorkingCopy.id}/request`, {
                asDraft
            })
                .then((response) => {
                    this.contractWorkingCopy = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        finishContract() {
            this.loading = true;
            this.checkForUnsavedChanges()
                .then((differences) => {
                    if (differences.length > 0) {
                        let message = "There are unsaved changes in the following fields:\n\n";
                        for (let i = 0; i < differences.length; i++) {
                            message += differences[i] + "\n";
                        }

                        message += "\n\nDo you want to continue?";

                        if (!confirm(message)) {
                            this.loading = false;
                            return;
                        }
                    }

                    this.$axios.post(`/api/contracts/${this.contractWorkingCopy.id}/finish_setup`)
                        .then((response) => {
                            this.contractWorkingCopy = response.data;
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                        .finally(() => {
                            this.loading = false;
                        })

                });

        },
        async addNewSigner() {
            this.forceActiveTabNumber = null;

            const newId = this.generateUUID();
            this.contractWorkingCopy.contractRecipients.push({
                id: newId,
                contact: null,
                contractSignatureTabs: [],
                requestInitials: null
            });

            await this.$nextTick();
            this.forceActiveTabNumber = newId;
        },
        updateContractWorkingCopy(contract) {
            if (this.forceActiveTabNumber) {
                const recipients = contract.contractRecipients.filter(cr => cr.id === this.forceActiveTabNumber);
                if (recipients.length === 0) {
                    if (contract.contractRecipients.length > 0) {
                        this.forceActiveTabNumber = contract.contractRecipients.at(-1).id;
                    } else {
                        this.addNewSigner();
                    }
                }
            }


            this.contractWorkingCopy = contract;

            if (contract.contractRecipients && contract.contractRecipients.length === 0) {
                this.addNewSigner();
            }
        },
        onContractRecipientRemoved() {
            this.loading = true;
            this.$axios.get(`/api/contracts/${this.contractWorkingCopy.id}`)
                .then((response) => {
                    this.updateContractWorkingCopy(response.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        checkForUnsavedChanges() {
            return new Promise((resolve, reject) => {
                this.unsavedChanges = [];
                const differences = [];
                this.$axios.get(`/api/contracts/${this.contractWorkingCopy.id}`)
                    .then((response) => {
                        const serverVersion = response.data;

                        // check contract information
                        if (serverVersion.title !== this.contractWorkingCopy.title) {
                            differences.push("Contract title");
                        }
                        if (serverVersion.documentUrl !== this.contractWorkingCopy.documentUrl) {
                            differences.push("Contract document");
                        }
                        if (serverVersion.user !== this.contractWorkingCopy.user) {
                            differences.push("Contract owner");
                        }

                        // check contract recipients
                        if (serverVersion.contractRecipients.length !== this.contractWorkingCopy.contractRecipients.length) {
                            differences.push("Contract recipients");
                        } else {


                            for (let i = 0; i < this.contractWorkingCopy.contractRecipients.length; i++) {
                                // Check in ref
                                const hasUnsavedChanges = this.$refs[`signerForm-${this.contractWorkingCopy.contractRecipients[i].id}`][0].checkForUnsavedChanges();
                                if (hasUnsavedChanges) {
                                    differences.push("Signer data for " + this.contractWorkingCopy.contractRecipients[i].contact.name);
                                }
                            }
                        }

                        this.unsavedChanges = differences;

                        resolve(differences);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        removeUnsignedRecipient(recipient) {
            const recipientsToStay = this.contractWorkingCopy.contractRecipients.filter(cr => cr.id !== recipient.id);
            this.$set(this.contractWorkingCopy, 'contractRecipients', recipientsToStay);
            this.updateContractWorkingCopy(structuredClone(this.contractWorkingCopy));
        }
    }
}
