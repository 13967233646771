
import {mapActions, mapGetters} from "vuex";
import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";
import EditBaseGroupFields from "../groups/edit-base-group-fields";
import {INTEREST_LIST_STATUS, OPTION_INTERESTED} from "../../mixins/interest-list-status";
import modals from "../../mixins/modals";
import HintText from "../hint-text.vue";
import {ATTENDANCE_OPTIONS} from "../../mixins/attending";
import {SEVERITY_TIERS} from "../../mixins/red-flag";
import ContactBulkEdit from "./contact-bulk-edit";
import contactList from '~/mixins/contact-list';

export default {
    name: "ContactBulkOperationForm",
    components: {
        HintText,
        ContactBulkEdit,
        EditBaseGroupFields,
        LoadingSpinner,
        SaveButton,
    },
    mixins: [contactList, modals],
    props: {
        filters: {
            type: Object,
            default: () => {
                return {}
            }
        },
        contactList: {
            type: Object,
            default: null,
        },
        bulkContacts: {
            type: Object,
            required: true,
        },
        contactLoading: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default: null
        },
        allowResendEventConfirmEmail: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            emailAdresses: [],
            contactIds: [],
            contacts: [],
            ATTENDANCE_OPTIONS,
            SEVERITY_TIERS,
            operation: "email",
            groupOption: "existing",
            groupOptions: [
                {value: 'existing', label: 'Existing Group'},
                {value: 'new', label: 'New Group'},
            ],
            selectedGroup: null,
            groupsLoading: false,
            defaultNewGroup: {isPrivate: true},
            newGroup: Object.assign({}, this.defaultNewGroup),
            running: false,
            loading: false,
            contactListOptions: [],
            newContactValues: {},
            selectedEvent: null,
            updateIsAttending: false,
            selectedIsAttending: null,
            updateHasAttended: false,
            selectedHasAttended: null,
            removeAttendance: false,
            exportFormat: 'docx',
            selectedCopySortOrder: 'Job Title',
            loadingCharacteristics: false,
            companyCharacteristics: [],
            positiveCompanyCharacteristics: [],
            negativeCompanyCharacteristics: [],
            selectedCompanyForInterestList: null,
            selectedCompaniesForInterestList: [],
            selectedStatusForInterestList: null,
            commentForInterestList: null,
            selectedCompanyGroup: null,
            duplicatedContactsForInterestList: [],
            INTEREST_LIST_STATUS,
            redFlagSeverity: null,
            redFlagReason: '',
            modal: null,
            inactivePermanently: false,
            sensitiveOperations: [
                'exclude_from_emails',
                'group_remove',
                'group_remove_all',
                'event_attendance',
                'interest_list_add',
                'interest_list_remove',
                'contact_set_temporary',
                'contact_update_company_by_email',
                'contact_bulk_edit',
                'contact_set_gender',
                'contact_bulk_delete',
                'add_investment_preferences',
                'remove_investment_preferences',
                'set_company_address_as_contact_address'
            ],
            selectedTargetingGroup: null,
        }
    },
    computed: {
        ...mapGetters({
            bulkContactFiltersHash: 'app/bulkContactFiltersHash',
        }),
        bulkOptionsGrouped() {
            return {
                // Contact Group actions
                "Contact Groups": {
                    secondary: false,
                    options: [
                        {
                            value: 'group_existing',
                            label: 'Add to Existing Group',
                        },
                        {
                            value: 'group_new',
                            label: 'Create New Group',
                        },
                    ]
                },
                "Company Groups": {
                    secondary: false,
                    options: [
                        {
                            value: 'company_group_existing',
                            label: 'Add Contact Companies to Existing Company Group',
                        },
                        {
                            value: 'company_group_new',
                            label: 'Create New Company Group from Contact Companies',
                        },
                    ]
                },
                "Targeting Groups": {
                    secondary: false,
                    options: [
                        {
                            value: 'targeting_group_existing',
                            label: 'Add to Existing Group',
                        },
                    ]
                },
                // Email actions
                "Email actions": {
                    secondary: false,
                    options: [
                        {
                            value: 'email',
                            label: 'Send Email',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                        {
                            value: 'copy_email',
                            label: 'Copy Email Addresses (Clipboard)',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                        {
                            value: 'resend_confirmation_mail',
                            label: 'Resend Confirmation Mail',
                            // Add any necessary conditions for disabling the option
                            disabled: !this.allowResendEventConfirmEmail || this.moreThan100Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                    ]
                },
                // Export actions
                "Export Actions": {
                    secondary: false,
                    options: [
                        {
                            value: 'export_as_excel',
                            label: 'Export as Excel',
                        },
                        {
                            value: 'export_briefing_book',
                            label: 'Export Briefing Book',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                    ]
                },
                "Interest List": {
                    secondary: true,
                    options: [
                        {
                            value: 'interest_list_add',
                            label: 'Add to Interest List',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                        {
                            value: 'interest_list_remove',
                            label: 'Remove from Interest List',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                    ],
                },
                "Investment Preferences": {
                    secondary: true,
                    options: [
                        {
                            value: 'add_investment_preferences',
                            label: 'Add investment preferences',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                        {
                            value: 'remove_investment_preferences',
                            label: 'Remove investment preferences',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                    ],
                },
                // Other actions
                "Edit": {
                    secondary: true,
                    options: [
                        {
                            value: 'contact_bulk_edit',
                            label: 'Edit: Change Type, Investor Tier, Department, and/or Company',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                        {
                            value: 'exclude_from_emails',
                            label: 'Exclude from Emails',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                        {
                            value: 'contact_set_gender',
                            label: 'Set Gender',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                        {
                            value: 'contact_set_temporary',
                            label: 'Set Temporary',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                        {
                            value: 'contact_update_company_by_email',
                            label: 'Change Company to Company derived from Email Address',
                            disabled: this.moreThan1000Contacts,
                            hint: "Limit: 1000 contacts"
                        },
                        {
                            value: 'set_company_address_as_contact_address',
                            label: 'Set Company Address as Contact Address',
                            disabled: this.moreThan100Contacts,
                            hint: "Limit: 100 contacts"
                        }
                    ]
                },
                "Other": {
                    secondary: true,
                    options: [
                        {
                            value: 'flag',
                            label: 'Add Flag or add to Blacklist',
                        },
                        {
                            value: 'event_attendance',
                            label: 'Update Attendance at an Event',
                        },
                        {
                            value: 'event_sessions_1x1',
                            label: 'Send Email with Sessions 1x1 Links (only to contacts that can request meetings in Sessions)',
                            disabled: !this.event || !this.event.sessionsId,
                            hint: 'Contacts that are not registered in Sessions will be filtered out.'
                        },
                        {
                            value: 'event_sessions_agenda',
                            label: 'Send Email with Sessions Agenda Links',
                            disabled: !this.event || !this.event.sessionsId,
                            hint: 'Contacts that are not registered in Sessions will be filtered out.'
                        },
                        {
                            value: 'event_sessions_meeting_management',
                            label: 'Send Email with Sessions Meeting Management Links',
                            disabled: !this.event || !this.event.sessionsId,
                            hint: 'Contacts that are not registered in Sessions will be filtered out.'
                        },
                        {
                            value: 'mark_known_personally',
                            label: 'Mark as Known Personally',
                            secondary: true,
                        },
                    ]
                },
                "Danger Zone": {
                    secondary: true,
                    isDangerous: true,
                    options: [
                        {
                            value: 'group_remove',
                            label: 'Remove From Contact Group',
                        },
                        {
                            value: 'group_remove_all',
                            label: 'Remove From All Contact Groups',
                        },
                        {
                            value: 'contact_bulk_delete',
                            label: 'Delete All',
                        },
                    ]
                },
            };
        },
        bulkOptions() {
            return [
                // Contact Group actions
                {
                    value: 'group_existing',
                    label: 'Add to Existing Group',
                    secondary: false,
                },
                {
                    value: 'group_new',
                    label: 'Create New Group',
                    secondary: false,
                },
                // Company Groups
                {
                    value: 'company_group_existing',
                    label: 'Add Contact Companies to Existing Company Group',
                    secondary: false,
                },
                {
                    value: 'company_group_new',
                    label: 'Create New Company Group from Contact Companies',
                    secondary: false,
                },
                {
                    value: 'group_remove',
                    label: 'Remove From Group',
                    secondary: false,
                },
                {
                    value: 'group_remove_all',
                    label: 'Remove From All Groups',
                    secondary: false,
                },
                // Targeting Group actions
                {
                    value: 'targeting_group_existing',
                    label: 'Add to Existing Targeting Group',
                    disabled: this.moreThan1000Contacts,
                    secondary: false,
                },
                // Email actions
                {
                    value: 'email',
                    label: 'Send Email',
                    disabled: this.moreThan1000Contacts,
                    secondary: false,
                },
                {
                    value: 'copy_email',
                    label: 'Copy Email Addresses (Clipboard)',
                    disabled: this.moreThan1000Contacts,
                    secondary: false,
                },
                {
                    value: 'resend_confirmation_mail',
                    label: 'Resend Confirmation Mail',
                    // Add any necessary conditions for disabling the option
                    disabled: !this.allowResendEventConfirmEmail || this.moreThan1000Contacts,
                    secondary: false,
                },
                // Export actions
                {
                    value: 'export_as_excel',
                    label: 'Export as Excel',
                    secondary: false,
                },
                {
                    value: 'export_briefing_book',
                    label: 'Export Briefing Book',
                    disabled: this.moreThan1000Contacts,
                    secondary: false,
                },
                // Other actions
                {
                    value: 'flag',
                    label: 'Add Flag or add to Blacklist',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'exclude_from_emails',
                    label: 'Exclude from Emails',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'event_attendance',
                    label: 'Update Attendance at an Event',
                    secondary: true,
                },
                {
                    value: 'event_sessions_1x1',
                    label: 'Send Email with Sessions 1x1 Links',
                    disabled: !this.event || !this.event.sessionsId
                },
                {
                    value: 'event_sessions_agenda',
                    label: 'Send Email with Sessions Agenda Links',
                    disabled: !this.event || !this.event.sessionsId
                },
                {
                    value: 'event_sessions_meeting_management',
                    label: 'Send Email with Sessions Meeting Management Links',
                    disabled: !this.event || !this.event.sessionsId
                },
                {
                    value: 'interest_list_add',
                    label: 'Add to Interest List',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'interest_list_remove',
                    label: 'Remove from Interest List',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'contact_set_temporary',
                    label: 'Set Contacts temporary',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'contact_update_company_by_email',
                    label: 'Change Company to Company derived from Email Address',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'contact_bulk_edit',
                    label: 'Edit: Change Type, Investor Tier, Department, and/or Company',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'contact_set_gender',
                    label: 'Set Gender',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'contact_bulk_delete',
                    label: 'Delete for Everyone',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'add_investment_preferences',
                    label: 'Add investment preferences',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'remove_investment_preferences',
                    label: 'Remove investment preferences',
                    disabled: this.moreThan1000Contacts,
                    secondary: true,
                },
                {
                    value: 'set_company_address_as_contact_address',
                    label: 'Set Company Address as Contact Address',
                    disabled: this.moreThan100Contacts,
                    secondary: true,
                },
                {
                    value: 'mark_known_personally',
                    label: 'Mark as Known Personally',
                    secondary: true,
                },
            ];
        },
        moreThan1000Contacts() {
            return this.bulkContacts && this.bulkContacts.count > 1000;
        },
        moreThan100Contacts() {
            return this.bulkContacts && this.bulkContacts.count > 100;
        }
    },
    watch: {
        async operation() {
            if (this.operation === 'group_remove' && this.contactList) {
                this.selectedGroup = this.contactList;
                this.contactListOptions = [this.contactList];
            } else if (this.operation === 'group_existing') {
                this.selectedGroup = null;
                this.groupsLoading = true;
                this.contactListOptions = await this.loadMostUsedContactLists();
                this.groupsLoading = false;
            } else if (this.operation === 'interest_list_add') {
                this.selectedCompanyForInterestList = null;
                this.commentForInterestList = null;
                this.selectedStatusForInterestList = OPTION_INTERESTED;
            } else if (["add_investment_preferences", "remove_investment_preferences"].includes(this.operation)) {
                this.positiveCompanyCharacteristics = [];
                this.negativeCompanyCharacteristics = [];
                if (this.companyCharacteristics.length === 0) {
                    this.loadCompanyCharacteristics();
                }
            } else {
                // FIXME Does the below really need to be set for all other operations?
                this.selectedGroup = null;
                this.contactListOptions = [];
            }
            this.newGroup = Object.assign({}, this.defaultNewGroup);
        },
        selectedCompanyForInterestList() {
            if (this.selectedCompanyForInterestList) {
                this.loadDuplicatedInterestListItemContacts()
            }
        }
    },
    methods: {
        ...mapActions({
            setBulkContactFiltersHash: "app/setBulkContactFiltersHash",
        }),
        confirmOperation() {
            if (this.sensitiveOperations.includes(this.operation)) {
                // Find out the operation name
                let bulkConfirmMessage = "Do you want to proceed?";
                const operationName = this.getLabelForValue(this.bulkOptions, this.operation);
                if (this.operation === "contact_bulk_delete") {
                    bulkConfirmMessage = `Are you sure you want to delete ${this.bulkContacts.count} contacts? These contacts will be set inactive for everyone.`;
                } else if (operationName) {
                    bulkConfirmMessage = "Do you want to proceed with action '" + operationName + "'?";
                }

                if (confirm(bulkConfirmMessage)) {
                    this.submitForm();
                }

            } else {
                this.submitForm();
            }
        },
        submitForm() {
            switch (this.operation) {
                case "email": {
                    this.$emit("submitted");

                    this.$router.push("/emails/compose/bulk")

                    break;
                }

                case "mark_known_personally": {
                    this.running = true;

                    this.$axios.post(`/api/contacts/mark_known_personally`, {hash: this.bulkContacts.hash}).then(response => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Success",
                            message: "Contacts marked as known personally.",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }

                case "group_existing": {
                    this.running = true;

                    this.addContactsToList(this.selectedGroup.id, null, this.bulkContacts.hash).then(() => {
                        this.$emit("submitted");
                    }).finally(() => {
                        this.running = false;
                    });

                    break;
                }

                case "group_new": {
                    this.running = true;

                    let sharedWithUsers = [];
                    if (this.newGroup.hasOwnProperty('sharedWithUsers')) {
                        sharedWithUsers = this.newGroup.sharedWithUsers.map(u => u.id);
                    }

                    this.$axios.post('/api/contact_lists/create_from_ids', {
                        description: this.newGroup.description,
                        hash: this.bulkContacts.hash,
                        isPrivate: this.newGroup.isPrivate,
                        title: this.newGroup.title,
                        sharedWithUsers,
                    }).then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Group Created",
                            message: "Contact group successfully created",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }).finally(() => {
                        this.$emit("submitted");
                        this.running = false
                    });

                    break;
                }

                case "company_group_new": {
                    this.running = true;

                    let sharedWithUsers = [];
                    if (this.newGroup.hasOwnProperty('sharedWithUsers')) {
                        sharedWithUsers = this.newGroup.sharedWithUsers.map(u => u.id);
                    }

                    this.$axios.post('/api/company_groups/create_from_contact_ids', {
                        description: this.newGroup.description,
                        hash: this.bulkContacts.hash,
                        isPrivate: this.newGroup.isPrivate,
                        isTemporary: this.newGroup.isTemporary,
                        title: this.newGroup.title,
                        sharedWithUsers,
                    }).then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Company Group Created",
                            message: "Company group successfully created",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }).finally(() => {
                        this.$emit("submitted");
                        this.running = false
                    });

                    break;
                }

                case "company_group_existing": {
                    this.running = true;

                    this.$axios.post(`/api/company_groups/add_from_contacts/${this.selectedCompanyGroup.id}`, {
                        hash: this.bulkContacts.hash
                    }).then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Company Group Updated",
                            message: "Contact Companies successfully added to the company group",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });

                    break;
                }

                case "group_remove": {
                    this.running = true;

                    this.removeContactsFromList(this.selectedGroup.id, null, this.bulkContacts.hash).then(() => {
                        this.$emit("submitted");
                    }).finally(() => {
                        this.running = false;
                    });

                    break;
                }

                case "group_remove_all": {
                    this.running = true

                    this.$axios.post(`/api/contact_lists/remove_from_all`, {
                        hash: this.bulkContacts.hash
                    }).then(() => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Group Updated",
                            message: "Contacts successfully removed from all associated groups",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }).finally(() => {
                        this.running = false
                        this.$emit("submitted");
                    });
                    break;
                }

                case "copy_email": {
                    this.running = true;

                    this.$axios.post(`/api/contacts/sort_emails`, {
                        hash: this.bulkContacts.hash,
                        sortOrder: this.selectedCopySortOrder,
                    }).then(response => {
                        this.copyTextToClipboard(response.data.join(";"), "Email addresses copied!").catch(err => {
                            console.error(err);
                        });

                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted", false);
                    });

                    break;
                }

                case "exclude_from_emails": {
                    this.running = true;

                    this.$axios.post(`/api/contacts/set_excluded_from_emails`, {hash: this.bulkContacts.hash}).then(response => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contacts Updated",
                            message: `${this.pluralize(response.data.changed, 'contact has', 'contacts have')} been updated` +
                                (response.data.unchanged > 0
                                    ? ` and ${this.pluralize(response.data.unchanged, 'contact was', 'contacts were')} already excluded from emails.`
                                    : '.'
                                ),
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });

                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }

                case "contact_set_temporary": {
                    this.running = true;

                    this.$axios.post(`/api/contacts/set_temporary`, {hash: this.bulkContacts.hash}).then(response => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contacts Updated",
                            message: `${this.pluralize(response.data.changed, 'contact has', 'contacts have')} been updated` +
                                (response.data.unchanged > 0
                                    ? ` and ${this.pluralize(response.data.unchanged, 'contact was', 'contacts were')} already marked as temporary.`
                                    : '.'
                                ),
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });

                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }

                case "event_attendance": {
                    if (!this.selectedEvent) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "danger",
                            title: "Missing Event",
                            message: "Please select an event!",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                        return;
                    }

                    if (!this.updateIsAttending && !this.updateHasAttended && !this.removeAttendance) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "danger",
                            title: "Missing Attendance",
                            message: "Please select how the attendance of the contacts for the selected event should be updated!",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                        return;
                    }

                    this.running = true;

                    const data = {
                        hash: this.bulkContacts.hash,
                        eventId: this.selectedEvent.id,
                        removeAttendance: this.removeAttendance,
                    };

                    if (this.updateIsAttending) {
                        data.isAttending = this.selectedIsAttending;
                    }

                    if (this.updateHasAttended) {
                        data.hasAttended = this.selectedHasAttended;
                    }

                    this.$axios.post(`/api/contacts/update_event_attendance`, data).then(response => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contacts Updated",
                            message: `Attendance of ${this.pluralize(response.data.changed, 'contact')} has been updated` +
                                (response.data.unchanged > 0 ? ` and ${this.pluralize(response.data.unchanged, 'contact')} did not need to be updated.` : '.'),
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });

                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }

                case "event_sessions_1x1":
                case "event_sessions_agenda":
                case "event_sessions_meeting_management": {
                    this.running = true;

                    let type = null;
                    switch (this.operation) {
                        case "event_sessions_1x1":
                            type = "1x1";
                            break
                        case "event_sessions_agenda":
                            type = "agenda";
                            break
                        case "event_sessions_meeting_management":
                            type = "meeting_management";
                            break;
                        default:
                            console.error("Invalid operation", this.operation);
                            return;
                    }

                    this.$axios.post(`/api/events/prepare_sessions_email/${this.event.id}`, {
                        hash: this.bulkContacts.hash,
                        type,
                    }).then(response => {
                        this.$router.push(`/emails/${response.data.id}`);
                        const toastId = this.generateUUID();

                        let templateVar = null;
                        let templateVarName = null;
                        switch (this.operation) {
                            case "event_sessions_1x1":
                                templateVar = "SESSIONS_JOURNEY_URL";
                                templateVarName = "Sessions 1x1 URL";
                                break;
                            case "event_sessions_agenda":
                                templateVar = "SESSIONS_AGENDA_URL";
                                templateVarName = "Sessions Agenda URL";
                                break;
                            case "event_sessions_meeting_management":
                                templateVar = "SESSIONS_MEETING_MANAGEMENT_URL";
                                templateVarName = "Sessions Meeting Management URL";
                                break;
                        }

                        // const templateVar = this.operation === "event_sessions_1x1" ? "SESSIONS_JOURNEY_URL" : "SESSIONS_AGENDA_URL";
                        // const templateVarName = this.operation === "event_sessions_1x1" ? "Sessions 1x1 URL" : "Sessions Agenda URL";

                        this.addToast({
                            type: "success",
                            title: "Email prepared",
                            message: 'Use the placeholder labeled "' + templateVarName + '" (' + templateVar + ') to include the link to Sessions',
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });

                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }
                case 'resend_confirmation_mail': {
                    this.running = true;

                    this.$axios.post(`/api/events/prepare_multi_confirmation_email/${this.bulkContacts.hash}`, {
                        eventId: this.event.id,
                    })
                        .then(response => {
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: "success",
                                title: "Success",
                                message: "Confirmation email has been prepared.",
                                id: toastId,
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            });
                            // this.submitted();
                            this.$emit("submitted");
                            this.$router.push('/emails/' + response.data.id);
                        })
                        .catch((e) => {
                            console.error(e);
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: "danger",
                                title: "Error",
                                message: "Confirmation email could not be created.",
                                id: toastId,
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            });
                        });

                    break;
                }
                case "contact_update_company_by_email": {
                    this.running = true;

                    this.$axios.post(`/api/contacts/update_company_by_email`, {hash: this.bulkContacts.hash}).then(response => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contacts Updated",
                            message: `For ${this.pluralize(response.data.updated, 'contact')} the company was successfully updated, ` +
                                `for ${this.pluralize(response.data.unchanged, 'contact')} the current company was found again, ` +
                                `for ${this.pluralize(response.data.noCompanyForEmail, 'contact')} a company could not be found` +
                                (response.data.noEmail > 0 ? ` and ${this.pluralize(response.data.noEmail, 'contact has', 'contacts have')} no email.` : '.'),
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });

                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }

                case "contact_bulk_edit":
                case "contact_set_gender": {
                    if (Object.keys(this.newContactValues).length === 0) {
                        alert('At least one property must be specified to edit');
                        return;
                    }

                    this.running = true;
                    const payload = {
                        hash: this.bulkContacts.hash,
                        valuesToSetIds: {},
                    };

                    for (const key in this.newContactValues) {
                        if (key === 'gender') {
                            payload.valuesToSetIds[key] = this.newContactValues[key];
                        } else if (key === 'investorTier') {
                            payload.valuesToSetIds[key] = this.newContactValues[key];
                        } else {
                            payload.valuesToSetIds[key] = this.extractNumericId(this.newContactValues[key]);
                        }
                    }

                    this.$axios.post(`/api/contacts/bulk_edit`, payload).then(response => {
                        const toastId = this.generateUUID();

                        this.addToast({
                            type: "success",
                            title: "Contacts Updated",
                            message:
                                `${this.pluralize(response.data.updated, 'contact has', 'contacts have')} been updated` +
                                (response.data.unchanged > 0
                                    ? ` and ${this.pluralize(response.data.unchanged, 'contact')} have remained the same.`
                                    : '.'
                                ),
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }

                case "contact_bulk_delete": {
                    this.running = true;
                    this.$axios.post(`/api/contacts/bulk_delete`, {
                        hash: this.bulkContacts.hash,
                        inactivePermanently: this.inactivePermanently
                    }).then(response => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: 'success',
                            title: 'Deleted successfully',
                            message: `${this.pluralize(response.data.deleted, 'contact has', 'contacts have')} been deleted`,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }

                case 'export_briefing_book': {
                    this.running = true;
                    this.$axios.post('/api/contact_sheets/generate', {
                        hash: this.bulkContacts.hash,
                        format: this.exportFormat,
                    })
                        .then(response => {
                            this.loading = false;
                            const exportUrl = `${this.$axios.defaults.baseURL}/api/contact_sheets/download/${response.data.key}`
                            window.open(exportUrl, '_blank').focus();
                        })
                        .finally(() => {
                            this.loading = false;
                            this.$emit("submitted");
                        });
                    break;
                }

                case 'export_as_excel':
                    this.$emit("export-as-excel");
                    break

                case 'interest_list_add': {
                    this.running = true;
                    this.$axios.post('/api/interest_list/add_bulk_contacts/' + this.selectedCompanyForInterestList.id, {
                        hash: this.bulkContacts.hash,
                        status: this.selectedStatusForInterestList,
                        comment: this.commentForInterestList
                    }).then((response) => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contacts added to Interest List",
                            message: response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }

                case 'add_investment_preferences':
                case 'remove_investment_preferences':
                    this.running = true;

                    // eslint-disable-next-line no-case-declarations
                    const add = this.operation === 'add_investment_preferences';
                    // eslint-disable-next-line no-case-declarations
                    const positiveCompanyCharacteristicIds = this.positiveCompanyCharacteristics.map(cc => cc.id);
                    // eslint-disable-next-line no-case-declarations
                    const negativeCompanyCharacteristicIds = this.negativeCompanyCharacteristics.map(cc => cc.id);

                    this.$axios.post(`/api/contacts/investment_preferences/update`, {
                        hash: this.bulkContacts.hash,
                        add,
                        positiveCompanyCharacteristicIds,
                        negativeCompanyCharacteristicIds,
                    }).then(response => {
                        const toastId = this.generateUUID();

                        const fullyUpdatedContacts = response.data.fullyUpdatedContacts;
                        const partiallyOrNotUpdatedContacts = response.data.partiallyOrNotUpdatedContacts;
                        const partiallyOrNotUpdatedContactsCount = partiallyOrNotUpdatedContacts.length;

                        if (partiallyOrNotUpdatedContactsCount === 0) {
                            this.addToast({
                                type: "success",
                                title: "Investment Preferences Updated",
                                message: `Investment Preferences have been updated for ${this.pluralize(fullyUpdatedContacts.length, 'contact')}.`,
                                id: toastId,
                            });
                        } else {

                            const failedContactIds = partiallyOrNotUpdatedContacts.map(c => c.id);

                            const filter = {
                                search: failedContactIds.join(','),
                            };

                            this.addToast({
                                type: "warning",
                                title: "Investment Preferences partially Updated",
                                message: `The investment preferences have been updated for ${this.pluralize(fullyUpdatedContacts.length, 'contact')}. ` +
                                    `However, they were not or only partially updated for ${this.pluralize(partiallyOrNotUpdatedContactsCount, 'contact')}. ` +
                                    `You can click here to proceed with updating the remaining contacts.`,
                                onClicked: () => {
                                    this.$router.push('/contacts?' + this.buildQueryString(filter));
                                },
                                id: toastId,
                            });
                        }

                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });

                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });

                    break;

                case 'interest_list_remove': {
                    this.running = true;
                    const companyIds = this.selectedCompaniesForInterestList.map(c => c.id)
                    this.$axios.post('/api/interest_list/remove_bulk_contacts/', {
                        hash: this.bulkContacts.hash,
                        companyIds,
                    }).then((response) => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contacts removed from Interest List(s)",
                            message: response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                }

                case 'set_company_address_as_contact_address':
                    this.running = true;
                    this.$axios.post('/api/contacts/set_company_address', {
                        hash: this.bulkContacts.hash,
                    }).then((response) => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contacts Address Updated",
                            message: response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }).finally(() => {
                        this.running = false;
                        this.$emit("submitted");
                    });
                    break;
                case 'flag':
                    this.running = true;
                    this.$axios.post('/api/contacts/bulk_flag', {
                        hash: this.bulkContacts.hash,
                        severity: this.redFlagSeverity.value,
                        reason: this.redFlagReason,
                    })
                        .then((response) => {
                            let title = "Contacts have been flagged.";
                            if (this.redFlagSeverity.value >= 50) {
                                title = "Contacts have been added to the Blacklist.";
                            }

                            const message = `Created: ${response.data.created}, Updated: ${response.data.updated}`;

                            console.log("Severity", this.redFlagSeverity);
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: "success",
                                title,
                                message,
                                id: toastId,
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            });
                        }).finally(() => {
                            this.running = false;
                            this.$emit("submitted");
                        });
                    break;
                case 'targeting_group_existing':
                    this.running = true;
                    this.$axios.patch(`/api/targeting_groups/${this.selectedTargetingGroup.id}/add_members`, {
                        contactsHash: this.bulkContacts.hash,
                    }, {
                        headers: {
                            'Content-Type': 'application/merge-patch+json'
                        }
                    }).then(response => {
                        this.running = false;
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Success",
                            message: "Contacts have been added to targeting group.",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                        this.$emit("submitted");
                    });
                    break;
                default:
                    alert("Unknown Operation");
            }
        },
        loadContactList(id) {
            return this.$axios.get(`api/contact_lists/${id}`)
        },
        loadMostUsedContactLists() {
            return this.$axios.get("/api/contact_lists/most_used?groups[]=contact_list:basics").then(response => {
                return response.data["hydra:member"];
            });
        },
        loadCompanyCharacteristics() {
            this.loadingCharacteristics = true;
            this.$axios.get('/api/company_characteristics').then((response) => {
                this.companyCharacteristics = response.data['hydra:member'];
            }).finally(() => {
                this.loadingCharacteristics = false;
            });
        },
        loadDuplicatedInterestListItemContacts() {
            this.running = true
            this.$axios.post("/api/interest_list/interestListItem_duplicate/" + this.selectedCompanyForInterestList.id, {
                hash: this.bulkContacts.hash,
            }).then(response => {
                this.duplicatedContactsForInterestList = response.data;
            }).finally(() => {
                this.running = false;
            });
        },
        getLabelForValue(arr, value) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].value === value) {
                    return arr[i].label;
                }
            }
            return null;
        },
        resetSelectedIsAttending() {
            if (this.updateIsAttending) {
                this.removeAttendance = false;
            } else {
                this.selectedIsAttending = null;
            }
        },
        resetSelectedHasAttended() {
            if (this.updateHasAttended) {
                this.removeAttendance = false;
            } else {
                this.selectedHasAttended = null;
            }
        },
        resetUpdateAttendance() {
            if (this.removeAttendance) {
                this.updateIsAttending = false;
                this.selectedIsAttending = null;

                this.updateHasAttended = false;
                this.selectedHasAttended = null;
            }
        },
    }
}
