
import ContactsTable from "./contacts/contacts-table";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "ContactListCard",
    components: {ContactsTable},
    mixins: [optionButtonTooltip],
    props: {
        type: {
            type: String,
            default: "FAVORITES",
            validator: function validator(value) {
                return ["FAVORITES"].includes(value);
            },
        },
        narrow: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {}
    },
    computed: {},
    methods: {}
}
