
import modals from "../../mixins/modals";
import investmentPreferences from "../../mixins/investment-preferences";
import {OPTION_FUND} from "../../mixins/company-classification";
import LoadingSpinner from "../loading-spinner.vue";
import InvestmentPreferencesModal from "./investment-preferences-modal.vue";

export default {
    name: "InvestmentPreferencesCard",
    components: {LoadingSpinner, InvestmentPreferencesModal},
    mixins: [modals, investmentPreferences],
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            config: {
                columns: [
                    {
                        headline: "Group",
                    },
                    {
                        headline: "Characteristic",
                    }
                ],
                pagination: 5
            },
            modal: null,
            modalKey: "investPrefModal",
            suggestions: [],
            addingSuggestion: false,
            loadingSuggestions: false,
        }
    },
    computed: {
        rows() {
            const investmentPreferences = structuredClone(this.contact.investmentPreferences);
            investmentPreferences.sort((a, b) => (a.isPositive === b.isPositive) ? 0 : a.isPositive ? -1 : 1);
            return investmentPreferences.map((ip) => {
                return [
                    {
                        type: "cellWithTitle",
                        text: ip.companyCharacteristic.companyCharacteristicGroup.title,
                        classes: !ip.isPositive ? "text-danger" : "",
                        title: !ip.isPositive ? "Negative Preference" : "Positive Preference",
                    },
                    {
                        type: "cellWithTitle",
                        text: ip.companyCharacteristic.title,
                        classes: !ip.isPositive ? "text-danger" : "",
                        title: !ip.isPositive ? "Negative Preference" : "Positive Preference",
                    }
                ]
            })

        }
    },
    watch: {
        contact: {
            handler() {
                this.loadSuggestions();
            },
            deep: true,
        }
    },
    mounted() {
        this.loadSuggestions();
    },
    methods: {
        async showEditForm() {
            this.modalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.editInvestmentPreferencesModal);
        },
        onPreferencesUpdated(contact) {
            this.closeModal(this.modal);
            this.modal = null;
            this.modalKey = this.generateUUID();
            this.$nextTick(() => {
                this.$emit('contact-updated', contact);
            });
        },
        loadSuggestions() {
            this.loadingSuggestions = true;
            if (this.contact.company && this.contact.company.classification !== OPTION_FUND) {
                return;
            }

            this.$axios.get('/api/contacts/investment_pref_suggestions/' + this.contact.id)
                .then((response) => {
                    this.suggestions = response.data;
                })
                .finally(() => {
                    this.loadingSuggestions = false;
                });
        },
        addSuggestion(characteristicId) {
            this.suggestions = [];
            this.addingSuggestion = true;

            const positiveCompanyCharacteristicIds = this.positiveCompanyCharacteristics.map(c => c.id);
            const negativeCompanyCharacteristicIds = this.negativeCompanyCharacteristics.map(c => c.id);

            if (positiveCompanyCharacteristicIds.includes(characteristicId)) {
                alert("Investment Preference exists already");
                return;
            }

            positiveCompanyCharacteristicIds.push(characteristicId);

            this.updateInvestmentPreferencesForContact(this.contact.id, positiveCompanyCharacteristicIds, negativeCompanyCharacteristicIds)
                .then((response) => {
                    this.$emit('contact-updated', response.data);
                })
                .finally(() => {
                    this.addingSuggestion = false;
                    this.loadSuggestions();
                })
        }
    }
}
