

export default {
    name: "ContactDepartmentSelect",
    components: {},
    props: {
        required: {
            type: Boolean,
        },
        value: {
            type: [Object, String, Number],
            required: false,
            default: undefined,
        },
        reduce: {
            type: Function,
            required: false,
            default: x => x['@id'],
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Department',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select Department...',
        },
    },
    data() {
        return {
            contactDepartmentOptions: [],
            selectedVariant: this.value,
        }
    },
    computed: {},
    watch: {
        selectedVariant: {
            handler(val) {
                this.$emit("input", val)
            },
        },
    },
    mounted() {
        this.loadContactDepartmentOptions();
    },
    created() {
    },
    methods: {
        async loadContactDepartmentOptions() {
            const response = await this.$axios.get("/api/contact_departments")

            this.contactDepartmentOptions = response.data['hydra:member'];
        },
    }
}
