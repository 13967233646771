

export default {
    name: "ContactCompanySelect",
    components: {},
    props: {
        required: {
            type: Boolean,
        },
        value: {
            type: [Object, String],
            required: false,
            default: undefined,
        },
        allowOptionsAdding: {
            type: Boolean,
            required: false,
            default: false,
        },
        includeAddress: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Company',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select Company...',
        },
    },
    data() {
        return {
            contactDepartmentOptions: [],
            selectedVariant: this.value,
        }
    },
    computed: {},
    watch: {
        selectedVariant: {
            handler(val) {
                this.$emit("input", val)
            },
        },
    },
    mounted() {
    },
    created() {
    },
    methods: {}
}
