
export default {
    name: "ContactGoogleIcon",
    props: {
        name: {
            type: String,
            required: true,
        },
        companyName: {
            type: String,
            default: null,
        }
    },
    computed: {
        searchWithGoogleUrl() {
            const query = this.name + (this.companyName ? (", " + this.companyName) : "");
            return "https://www.google.com/search?" + this.buildQueryString({"q": query});
        }
    },
}
