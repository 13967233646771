
import ContactName from "../contact-name.vue";
import RedFlag from "../../red-flag.vue";

export default {
    name: "ContactNameCell",
    components: {RedFlag, ContactName},
    props: {
        cell: {
            type: Object,
            required: true,
        },
        nameLinks: {
            type: Boolean,
            default: true
        },
        showThumbnails: {
            type: Boolean,
            default: false
        },
        showAssistantMarker: {
            type: Boolean,
            default: false
        },
        showRedFlag: {
            type: Boolean,
            default: true,
        },
    }
}
