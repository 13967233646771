
import LoadingSpinner from "../loading-spinner.vue";
import SaveButton from "../save-button.vue";
import {ATTENDANCE_OPTIONS} from "../../mixins/attending";

export default {
    name: "RemoveFromEventModal",
    components: {SaveButton, LoadingSpinner},
    props: {
        contactId: {
            type: Number,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            ATTENDANCE_OPTIONS,
            uuid: this.generateUUID(),
            isLoading: false,
            contactEventParticipation: null,
            interactions: [],
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.isLoading = true;

            this.$axios.get(`/api/events/contact_at_event/${this.event.id}/${this.contactId}`).then(response => {
                this.contactEventParticipation = response.data.contactEventParticipation;
                this.interactions = response.data.interactions;

                this.isLoading = false;
            })
        });

        this.$refs.modal.addEventListener('hide.bs.modal', () => {
            this.contactEventParticipation = null;
            this.interactions = [];
        });
    },
    methods: {
        removeFromEvent() {
            this.isLoading = true;

            this.$axios.delete(`/api/contact_event_participations/${this.contactEventParticipation.id}`).then(() => {
                this.$emit('removed');
                this.isLoading = false;
            });
        }
    }
}
