
import LoadingSpinner from "../loading-spinner";
import SaveButton from "../save-button";

export default {
    name: "CloneContactGroupModal",
    components: {LoadingSpinner, SaveButton},
    props: {
        contactListId: {
            type: Number,
            default: null,
        }
    },
    data() {
        return {
            contactListWorkingCopy: null,
            initialLoading: true,
            saving: false
        }
    },
    watch: {
        contactListId() {
            if (this.contactListId) {
                this.loadContactList();
            }
        }
    },
    methods: {
        loadContactList() {
            this.initialLoading = true;
            this.$axios.get(`/api/contact_lists/${this.contactListId}`).then((response) => {
                this.contactListWorkingCopy = structuredClone(response.data);
                if (this.initialLoading) {
                    this.contactListWorkingCopy.id = null
                    this.initialLoading = false;
                }
            })
        },
        resetWorkingCopy() {
            this.initialLoading = true;
            this.contactListWorkingCopy = null;
        },
        submitForm() {
            this.saving = true
            this.$axios.post("/api/contact_lists/clone/" + this.contactListId, {
                newTitle: this.contactListWorkingCopy.title,
            }).then((response) => {
                console.log(response.data)
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Saved",
                    message: "Group cloned",
                    id: toastId,
                })
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.saving = false;
                this.$emit('submitted')
            })
        }
    }
}
