
import EmailDropdown from "../email-dropdown.vue";

export default {
    name: "ContactEmailCell",
    components: {EmailDropdown},
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
}
