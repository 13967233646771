
import moment from "moment";
import ShowInteractionModal from "../interactions/show-interaction-modal";
import EditNoteModal from "../../components/notes/edit-note-modal";
import ContactName from "./contact-name";
import optionButtonTooltip from "~/mixins/option-button-tooltip";
import modals from "~/mixins/modals"
import emailPopoverActionsMixin from "~/mixins/email-popover-actions";

export default {
    name: "ContactsOfRecentInteractionsCard",
    components: {ShowInteractionModal, ContactName, EditNoteModal},
    mixins: [
        optionButtonTooltip,
        modals,
        emailPopoverActionsMixin
    ],


    data() {
        return {
            userTimezone: moment.tz.guess(),
            interactionToShow: null,
            tableUpdated: moment().unix(),
            hideEmails: true,
            defaultNumber: 20,
            maxNumber: 100,
            minNumber: 1,
            numberOfContacts: 20,
            numberOfContactsForUrl: 20,
            numberTimeout: null,
            preventReload: false,
            noteToEdit: null,
            modal: null,
            selectedContact: null,
            addNoteLoading: null,
        }
    },
    computed: {
        numberOfContactsProperty: {
            get() {
                return this.numberOfContacts
            },
            set(val) {
                val = [val].join("")

                this.preventReload = true;
                this.numberOfContacts = 0;

                this.$nextTick(() => {
                    this.preventReload = false;
                    this.$nextTick(() => {
                        if (val.match(/^-?\d+$/) && parseInt(val) < this.minNumber) {
                            this.numberOfContacts = this.minNumber;
                        } else if (val.match(/^-?\d+$/) && parseInt(val) > this.maxNumber) {
                            this.numberOfContacts = this.maxNumber;
                        } else if (val.match(/^-?\d+$/)) {
                            this.numberOfContacts = parseInt(val);
                        } else {
                            this.numberOfContacts = this.defaultNumber;
                        }
                    })
                });
            },

        },
        filters() {
            return {
                hide_emails: this.hideEmails
            }
        },
        config() {
            return {
                columns: [
                    {
                        headline: "Contact",
                    }, {
                        headline: "Date",
                    }, {
                        headline: "Subject",
                        breakpoint: "md"
                    }, {
                        headline: "Notes",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    }
                ],
                pagination: 10,
                ajaxUrl: '/api/contacts/recent_interactions?numberOfContacts=' + this.numberOfContactsForUrl,
            }
        }
    },
    watch: {
        numberOfContacts(val) {
            // console.log("prevent",this.preventReload,val > 0 && !this.preventReload);
            if (val > 0 && !this.preventReload) {
                clearTimeout(this.numberTimeout);
                this.numberTimeout = setTimeout(() => {
                    this.numberOfContactsForUrl = this.numberOfContacts;
                }, 250);
            }

        },
    },
    methods: {
        onUpdate() {
            this.$nextTick(() => {
                this.initializeOptionButtonTooltips();
            })
        },
        showInteraction(id) {
            this.interactionToShow = id;
            this.$nextTick(() => {
                this.openModal(this.$refs.showInteractionsModal);
            })
        },
        addNoteToContact(contactId) {
            this.addNoteLoading = contactId;
            const a = this.$axios.get("/api/contacts/" + contactId).then((res) => {
                this.selectedContact = res.data;
            })
            Promise.all([a]).then(() => {
                this.$axios.get("/api/notes/empty")
                    .then((response) => {
                        this.noteToEdit = response.data;
                    }).finally(() => {
                        this.addNoteLoading = null;
                        this.modal = this.openModal(this.$refs.addNoteToContactModal)
                    })

            })
        },
        closeNodeModal() {
            this.closeModal(this.modal);
            this.modal = null;
        },
        notesUpdated() {
            this.tableUpdated = moment().unix();
            this.closeModal(this.modal);
            this.modal = null;
        },
    }
}
