
import moment from "moment";
import LoadingSpinner from "../loading-spinner";
import UploadDropZone from "../upload-drop-zone";
import InfoText from "@/components/info-text";
import SearchSelect from "@/components/search-select";

export default {
    name: "ContactExcelImport",
    components: {
        UploadDropZone,
        LoadingSpinner,
        SearchSelect,
        InfoText,
    },
    props: {
        formName: {
            type: String,
            default: "import-form"
        },
        loadingOverlay: {
            type: Boolean,
            default: false
        },
        canImportBeTemporary: {
            type: Boolean,
            default: false
        },
        skipContactsWithoutEmail: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            importFile: "",
            loading: false,
            fileUploaded: false,
            uploadUrl: "/api/upload/excel",
            importUrl: "/api/contacts/do_import",
            dataUrl: "/api/contacts/import_data",
            // deleteUrl: "/api/contacts/delete_excel_file",
            data: [],
            selectedData: {},
            finalData: {},
            finalSelectData: {},
            finalDefaultData: {},
            finalConditionsData: {},
            finalCombinedData: {},
            checkboxes: {},
            highlightDropArea: false,
            finished: false,
            successfullyImported: false,
            draggable: true,
            scrollOptions: {
                useBothWheelAxes: true,
                wheelSpeed: 0.5,
                wheelPropagation: true,
            },
            headingRowTop: 0,
            selectsUpdates: {},
            isTemporaryImport: false,
            extra: {},
            collapseShow: {},
            bouncingError: false,
            bounceTimeout: null,
            showBackwardArrow: false,
            showForwardArrow: true,
            forwardInterval: null,
            backwardInterval: null,
            scrollOffset: 200
        }
    },
    computed: {

        hasComputedData() {
            const data = {};
            for (const section in this.data.neededData) {

                if (this.data.neededData.hasOwnProperty(section)) {


                    for (let i = 0; i < this.data.neededData[section].length; i++) {
                        const hasData = this.hasData(this.data.neededData[section][i].key);
                        data[this.data.neededData[section][i].key] = hasData ? 1 : null
                    }
                }
            }

            return data;
        },

        formattedNeededData() {

            if (!this.data) {
                return [];
            }

            const items = [];

            for (const section in this.data.neededData) {

                if (this.data.neededData.hasOwnProperty(section)) {


                    for (let i = 0; i < this.data.neededData[section].length; i++) {
                        if (this.data.neededData[section][i] && this.data.custom[this.data.neededData[section][i].key]) {
                            continue;
                        }

                        if (this.data.neededData[section][i].select && !this.data.neededData[section][i].switch) {
                            continue;
                        }

                        let set = false;

                        Object.keys(this.selectedData).forEach((key, index) => {
                            if (this.selectedData[key] && this.data.neededData[section][i]
                                && this.selectedData[key].key === this.data.neededData[section][i].key) {
                                set = true;
                            }
                        });

                        if (set) {
                            continue;
                        }


                        const option = Object.assign({}, this.data.neededData[section][i]);


                        option.section = section;


                        items.push(option);

                    }
                }

            }

            return items;


        },

        neededDataArray() {

            if (!this.data) {
                return [];
            }

            const items = [];

            for (const section in this.data.neededData) {
                for (let i = 0; i < this.data.neededData[section].length; i++) {
                    items.push(Object.assign({}, this.data.neededData[section][i]));
                }
            }

            return items;


        },


        availableSlots() {


            if (!this.data.maxCols) {
                return [];
            }

            const available = Array.apply(null, {length: this.data.maxCols + 1}).map(Number.call, Number);

            available.shift();

            for (const key in this.finalData) {


                if (this.data.custom[key]) {
                    continue
                }


                const index = available.indexOf(this.finalData[key]);


                if (this.checkboxes[(index + 1) + "_multiples"]) {
                    continue;
                }

                if (index !== -1) available[index] = null;

            }

            for (const key in this.finalCombinedData) {

                for (let i = 0; i < this.finalCombinedData[key].length; i++) {

                    const index = available.indexOf(this.finalCombinedData[key][i].column);

                    if (this.checkboxes[(index + 1) + "_multiples"]) {
                        continue;
                    }

                    if (index !== -1) available[index] = null;

                }

            }


            const filtered = available.filter(function (elem) {
                return elem !== null
            });

            const headingRow = this.data.testData[this.headingRow - 1];

            return filtered.map(function (entry) {


                const colName = headingRow ? headingRow[entry - 1] : "";

                if (colName) {
                    return {
                        column: entry,
                        label: entry + " (" + colName + ")",
                    }
                }

                return {
                    column: entry,
                    label: entry,
                }


            });
        },

        headingRow() {
            return parseInt(this.finalData.headingRow);
        },

        rowOffset() {
            return parseInt(this.finalData.rowOffset);
        },
        dataMissing() {

            const missing = {};

            for (const section in this.data.neededData) {
                missing[section] = false
                for (let i = 0; i < this.data.neededData[section].length; i++) {
                    const col = this.data.neededData[section][i];
                    if (col.required && !this.hasData(col.key)) {
                        missing[section] = true;
                        break;
                    }
                }
            }

            return missing;


        }
    },
    watch: {

        importFile(val) {
            if (val) {
                this.upload();
            }
        },

        headingRow: {

            handler(val) {

                const intVal = parseInt(val);

                if (!isNaN(intVal) && intVal < 1) {
                    this.$set(this.finalData, "headingRow", 1)
                }

                if (!isNaN(intVal) && val > this.data.testRows) {
                    this.$set(this.finalData, "headingRow", this.data.testRows)
                }


                setTimeout(() => {
                    const row = this.$refs['import-data-row-' + (val - 1)];
                    const container = this.$refs['import-data-container'];

                    if (!row) {
                        return;
                    }

                    this.headingRowTop = row[0].getBoundingClientRect().top - container.getBoundingClientRect().top;
                }, 1);
            },
            immediate: true


        },

        rowOffset(val) {

            if (val < 0) {
                this.$set(this.finalData, "rowOffset", 0);
            }
        },

        fileUploaded(val) {

            this.$emit("file-uploaded", val);

            if (val) {
                this.loading = "Preparing import...";


                this.$axios.get(this.dataUrl + "/" + this.fileUploaded).then(response => {

                    this.data = response.data;


                    for (const key in this.data.defaults) {
                        this.$set(this.finalData, key, this.data.defaults[key]);
                    }


                    this.$emit("data-loaded", this.data);

                    this.$nextTick(function () {
                        this.onImportDataScrollX();
                        this.autodetect();
                    });

                }
                ).catch(function (error) {
                    console.error("ERROR", error);

                }
                ).finally(() => {
                    this.loading = false;
                });
            }
        },
        hasComputedData: {
            handler() {
                if (this.$refs.importForm) {
                    this.$formulate.resetValidation(this.formName);
                }

            },
            deep: true,
        },
        data: {
            handler(val) {
                if (val && val.neededData) {

                    Object.keys(val.neededData).forEach(section => {

                        this.$nextTick(() => {

                            this.$refs[section.toLowerCase() + "-collapse"][0].addEventListener('show.bs.collapse', () => {
                                this.$set(this.collapseShow, section, true)
                            })
                            this.$refs[section.toLowerCase() + "-collapse"][0].addEventListener('hide.bs.collapse', () => {
                                this.$set(this.collapseShow, section, false)
                            })
                        });


                        val.neededData[section].forEach((data) => {
                            const extras = {};

                            if (data.ownContact) {
                                extras.ownContact = true;
                            }
                            if (data.overridable) {
                                extras.override = !!data.overrideDefault;
                            }

                            if (Object.keys(extras).length > 0) {
                                this.$set(this.extra, data.key, extras)
                            }

                        })

                    })
                }
            },
            deep: true,
        },
        showBackwardArrow(val) {
            if (!val) {
                clearInterval(this.backwardInterval)
            }
        },
        showForwardArrow(val) {
            if (!val) {
                clearInterval(this.forwardInterval)
            }
        }
    },

    methods: {

        doSubmit() {
            // TEST
        },

        dragover(event) {
            event.preventDefault();
            this.highlightDropArea = true;
        },
        dragleave(event) {
            // Clean up
            this.highlightDropArea = false;
        },
        drop(event) {
            event.preventDefault();
            this.highlightDropArea = false;

            if (event.dataTransfer.files[0]) {
                this.setFile(event.dataTransfer.files[0])
            }
        },
        setFileData(files) {
            console.log("setFiles!")
            if (files[0]) {
                this.setFile(files[0])
            }
        },

        getOptionLabel(option) {


            if (option instanceof Object) {
                return option.label
            } else {
                const headingRow = this.data.testData[this.headingRow - 1];

                if (headingRow) {
                    option = parseInt(option);
                    const colName = headingRow ? headingRow[option - 1] : "";
                    return option + " (" + colName + ")";
                } else {
                    return option;
                }
            }


        },
        submitForm() {
            this.$formulate.submit(this.formName);
            return new Promise((resolve, reject) => {

                this.$nextTick(() => {
                    if (this.$refs.importForm.hasErrors) {
                        reject(new Error("Has Errors"))
                        this.bouncingError = true;
                        clearTimeout(this.bounceTimeout)
                        this.bounceTimeout = setTimeout(() => {
                            this.bouncingError = false;
                        }, 5000)
                        return;
                    }

                    const normalizedData = {};
                    for (const key in this.finalData) {
                        if (this.finalData[key]) {
                            if (!this.data.custom[key]) {
                                normalizedData[key] = this.finalData[key] - 1
                            } else {
                                normalizedData[key] = this.finalData[key];
                            }
                        }
                    }

                    const normalizedCombined = {};
                    for (const key in this.finalCombinedData) {
                        const entries = [];
                        for (let i = 0; i < this.finalCombinedData[key].length; i++) {
                            entries.push(Object.assign({}, this.finalCombinedData[key][i]));
                            entries[i].column -= 1;

                        }

                        normalizedCombined[key] = entries;

                    }


                    const normalizedConditions = {};
                    for (const key in this.finalConditionsData) {
                        const entries = [];
                        for (let i = 0; i < this.finalConditionsData[key].length; i++) {
                            entries.push(Object.assign({}, this.finalConditionsData[key][i]));
                            entries[i].column -= 1;

                        }
                        normalizedConditions[key] = entries;

                    }

                    this.loading = "Importing...";

                    this.$axios.post(`${this.importUrl}/${this.fileUploaded}`, {
                        data: normalizedData,
                        select: this.finalSelectData,
                        default: this.finalDefaultData,
                        combined: normalizedCombined,
                        conditions: normalizedConditions,
                        extra: this.extra,
                        isTemporaryImport: this.isTemporaryImport,
                        skipContactsWithoutEmail: this.skipContactsWithoutEmail,
                    }).then(response => {
                        this.successfullyImported = true;
                        this.finished = true;

                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contacts Imported",
                            message: `${this.pluralize(response.data.updatedContacts.length, 'existing contact was', 'existing contacts were')} updated, ` +
                                `${this.pluralize(response.data.createdContacts.length, 'new contact was', 'new contacts were')} created.`,
                            id: toastId,
                        })
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })

                        this.$emit("submit");

                        resolve(response.data.updatedContacts.concat(response.data.createdContacts));

                    }
                    ).catch((error) => {

                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "danger",
                            title: "Error",
                            message: "Contact import failed, please try again! " + error,
                            id: toastId,
                        })
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })

                        reject(error);

                        console.error("ERROR", error);
                    }
                    ).finally(() => {
                        this.loading = false;
                    });
                })
            })
        },

        autodetect() {
            const detected = {};
            const duplicates = {};

            if (!this.headingRow) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "danger",
                    title: "Error",
                    message: "No heading row selected",
                    id: toastId,
                })
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
                return
            }


            for (let i = 0; i < this.data.testData[this.headingRow - 1].length; i++) {
                const text = this.data.testData[this.headingRow - 1][i];

                if (typeof text !== "string") {

                    continue;
                }

                let set = false;

                for (let j = 0; j < this.neededDataArray.length; j++) {
                    if (set) {
                        break;
                    }

                    let found = this.neededDataArray[j].label.toLowerCase().trim() === text.toLowerCase().trim().replace(/\s/g, ' ');

                    if (this.neededDataArray[j].synonyms) {


                        let counter = 0;
                        while (!found && counter < this.neededDataArray[j].synonyms.length) {
                            if (this.neededDataArray[j].synonyms[counter].toLowerCase().trim() === text.toLowerCase().trim().replace(/\s/g, ' ')) {
                                found = true;
                                break;
                            }
                            counter++;
                        }


                    }

                    if (found) {


                        for (const ind in this.selectedData) {

                            if (ind === i + 1) {
                                continue;
                            }

                            if (this.selectedData[ind] && this.selectedData[ind].key === this.neededDataArray[j].key) {
                                duplicates[this.neededDataArray[j].label] = ind + " | " + (i + 1);
                                this.$set(this.selectedData, ind, null);

                            }

                        }

                        this.$set(this.selectedData, i + 1, this.findData(this.neededDataArray[j].key));
                        this.$set(this.finalData, this.neededDataArray[j].key, i + 1);
                        set = true;
                        detected[this.neededDataArray[j].label] = i + 1;

                    }
                }

            }


            if (Object.keys(detected).length) {
                let output = "<strong>Auto-detected columns for row " + this.headingRow + ":</strong><br>";
                for (const label in detected) {
                    output += this.escapeHtml(label) + ": column " + this.escapeHtml(detected[label]) + "<br>";
                }

                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Success",
                    htmlMessage: output,
                    id: toastId,
                })
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })


            } else {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "warning",
                    title: "Error",
                    message: "No auto detecting possible for row " + this.headingRow,
                    id: toastId,
                })
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }

            if (Object.keys(duplicates).length) {
                let output = "<strong>Duplicates in Auto-detection:</strong><br>";
                for (const label in duplicates) {
                    output += this.escapeHtml(label) + ": columns " + this.escapeHtml(duplicates[label]) + "<br>";
                }

                const toastId = this.generateUUID();
                this.addToast({
                    type: "warning",
                    title: "Warning",
                    htmlMessage: output,
                    id: toastId,
                })
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })

            }

        },

        findData(key) {
            for (const section in this.data.neededData) {
                for (let i = 0; i < this.data.neededData[section].length; i++) {
                    if (this.data.neededData[section][i].key === key) {

                        const option = Object.assign({}, this.data.neededData[section][i]);

                        option.section = section;
                        return option;


                    }
                }
            }
            return null;
        },


        selectData(index) {
            this.$nextTick(() => {

                index = parseInt(index);


                for (const key in this.finalData) {

                    if (this.finalData.hasOwnProperty(key)) {


                        if (this.data.custom && this.data.custom[key]) {
                            continue;
                        }


                        if (this.finalData[key] === index) {
                            this.$set(this.finalData, key, null);
                        }
                    }
                }

                // delete former set data entries for this key
                // delete former set data entries for this key


                // delete former set selected data entries for this key
                for (let ind in this.selectedData) {


                    if (this.selectedData.hasOwnProperty(ind)) {

                        ind = parseInt(ind);

                        if (ind === index) {
                            continue;
                        }
                        if (this.selectedData && this.selectedData[ind] && this.selectedData[index]
                            && this.selectedData[ind].key === this.selectedData[index].key) {
                            this.$set(this.selectedData, ind, null);
                        }
                    }
                }

                // set data entry
                if (this.selectedData[index]) {
                    this.$set(this.finalData, this.selectedData[index].key, index);
                }

            });


        },

        assignData(key) {

            this.$nextTick(() => {

                // delete former set selected data entries for this key
                // delete former set selected data entries for this key
                for (const index in this.selectedData) {

                    if (this.selectedData.hasOwnProperty(index) && this.selectedData[index] && this.selectedData[index].key === key) {
                        this.$set(this.selectedData, index, null);
                    }

                }

                // set auto selected data
                if (this.finalData[key]) {
                    this.$set(this.selectedData, this.finalData[key], this.findData(key));
                }

                // reset list data if it is set
                this.$set(this.selectsUpdates, key, moment().unix());
                this.$set(this.finalSelectData, key, null);

            });


        },

        resetDataInput(key) {
            this.$set(this.finalData, key, null);
        },


        hasData(key) {

            if (this.finalData[key]) {
                return true;
            }

            if (!this.empty(this.finalSelectData[key])) {
                return true;
            }

            if (this.empty(this.finalCombinedData[key])) {
                return false;
            }

            for (let i = 0; i < this.finalCombinedData[key].length; i++) {
                if (this.finalCombinedData[key][i].column) {
                    return true;
                }
            }


        },

        getRows(items, split = 2) {

            const rows = [];

            items = items.slice();

            while (items.length > 0) {

                const howMany = (items.length >= split) ? split : items.length;
                const row = items.splice(0, howMany);
                rows.push(row)
            }

            return rows;
        },

        onImportDataScrollY(e) {
            const scroll = e.target.scrollTop;

            this.$refs['import-data-head'].style.top = scroll + "px";
        },

        onImportDataScrollX(e) {

            const scroll = e ? e.target.scrollLeft : 0;
            const width = this.$refs['import-data-container'].clientWidth;
            const pWidth = this.$refs['import-data-table'].clientWidth;

            this.showForwardArrow = pWidth > width + scroll;
            this.showBackwardArrow = scroll > 0;
        },

        empty(arr) {
            if (!arr) {
                return true;
            }
            if (!Object.keys(arr).length && !arr.length) {
                return true
            }
        },

        // deleteFile(clear = false) {
        //
        //     this.$axios.post(this.deleteUrl, {fileName: this.fileUploaded}).then(response => {
        //         console.log(response.data.message);
        //     }
        //     ).catch(function (error) {
        //         console.error("ERROR", error);
        //
        //     }
        //     ).finally(() => {
        //         if (clear) {
        //             this.loading = false;
        //         }
        //     });
        // },

        revertAll() {
            // this.deleteFile(true);

            this.data = [];
            this.fileUploaded = false;
            this.importFile = "";

            this.finalData = {};
            this.extra = {};
            this.finalSelectData = {};
            this.finalDefaultData = {};
            this.finalConditionsData = {};
            this.finalCombinedData = {};

            this.selectedData = {};
            this.checkboxes = {};
            this.successfullyImported = false;
            this.finished = false;
        },

        removeCombinedField(key, index) {
            const arr = this.finalCombinedData[key];
            arr.splice(index, 1);

            this.$set(this.finalCombinedData, key, arr);
        },

        addCombinedField(key) {
            let arr = this.finalCombinedData[key];

            if (this.finalData[key]) {
                this.$set(this.finalData, key, null);
                this.$set(this.selectsUpdates, key, moment().unix());
                this.$set(this.finalSelectData, key, null);

                for (const index in this.selectedData) {

                    if (this.selectedData[index] && this.selectedData[index].key === key) {
                        this.$set(this.selectedData, index, null);
                    }
                }


            }

            if (!arr) {
                arr = [];
            }

            arr.push({column: null, prefix: "", postfix: ""});

            this.$set(this.finalCombinedData, key, arr);

        },

        removeConditionField(key, index) {
            const arr = this.finalConditionsData[key];
            arr.splice(index, 1);

            this.$set(this.finalConditionsData, key, arr);
        },

        addConditionField(key) {
            let arr = this.finalConditionsData[key];

            if (!arr) {
                arr = [];
            }

            arr.push({condition: null, value: null, result: null});

            this.$set(this.finalConditionsData, key, arr);

        },


        getExcerpt(text) {

            if (!text) {
                return "";
            }

            if (typeof text !== "string" || text.length <= 30) {
                return text;
            }

            return text.substring(0, 30) + '...';
        },


        setFile(file) {
            this.importFile = file;
        },

        upload() {

            if (!this.importFile) {
                return;
            }

            this.loading = "Upload file...";


            const reader = new FileReader();
            reader.readAsDataURL(this.importFile)
            // directory
            reader.onerror = () => {
                console.log("Error!");
            };
            reader.onload = () => {
                this.$axios.post(
                    this.uploadUrl,
                    {
                        type: "contacts",
                        files: {
                            excelFile: reader.result,
                            extension: this.importFile.name.split('.').pop(),
                        }
                    }
                ).then(response => {

                    if (response.data.status) {
                        this.fileUploaded = response.data.fileName;
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Success",
                            message: response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    } else {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "danger",
                            title: "Error",
                            message: response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })

                    }
                    this.importFile = null;
                    this.loading = false;


                }).catch(function (e) {
                    console.log("ERROR", e);
                    this.loading = false;
                })
            };
        },

        overrideChanged(key) {
            const newOverrideValue = this.extra[key].override;
            const addressFields = ['address1', 'address2', 'zip', 'city', 'state', 'country'];
            if (addressFields.includes(key)) {
                for (const fieldName of addressFields) {
                    this.extra[fieldName].override = newOverrideValue;
                }
            }
        },
        backwardScroll() {
            this.$refs.scroller.$el.scrollTo({left: this.$refs.scroller.$el.scrollLeft - this.scrollOffset})
        },
        forwardScroll() {
            this.$refs.scroller.$el.scrollTo({left: this.$refs.scroller.$el.scrollLeft + this.scrollOffset})
        },
        startForwardTimer() {
            clearInterval(this.forwardInterval)
            this.forwardInterval = setInterval(() => {
                this.$refs.scroller.$el.scrollTo({left: this.$refs.scroller.$el.scrollLeft + this.scrollOffset})
            }, 500)
        },
        stopForwardTimer() {
            clearInterval(this.forwardInterval)
        },
        startBackwardTimer() {
            clearInterval(this.backwardInterval)
            this.backwardInterval = setInterval(() => {
                this.$refs.scroller.$el.scrollTo({left: this.$refs.scroller.$el.scrollLeft - this.scrollOffset})
            }, 500)
        },
        stopBackwardTimer() {
            clearInterval(this.backwardInterval)
        }
    }
}
