


export default {
    name: "KnownPeopleModal",

    props: {
        knownPeople: {
            type: Array,
            required: true
        },
        fromContactId: {
            type: Array,
            required: true
        }
    },
    computed: {
        key() {
            return this.fromContactId.join();
        }
    }
}
