
export default {
    name: "BioCard",
    props: {
        contact: {
            type: Object,
            required: true
        }
    },
}
