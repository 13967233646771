import { render, staticRenderFns } from "./contact-type-select.vue?vue&type=template&id=012122aa&scoped=true"
import script from "./contact-type-select.vue?vue&type=script&lang=js"
export * from "./contact-type-select.vue?vue&type=script&lang=js"
import style0 from "./contact-type-select.vue?vue&type=style&index=0&id=012122aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012122aa",
  null
  
)

export default component.exports