

import moment from "moment-timezone";
import modals from "../../mixins/modals";
import ConfirmModal from "../confirm-modal";
import TableActions from "../table-actions";
import LoadingSpinner from "../loading-spinner";
import ExpandableText from "../helpers/expandable-text.vue";
import EditPersonalInformationModal from "./edit-personal-information-modal";

export default {
    name: "PersonalInformationCard",
    components: {ExpandableText, LoadingSpinner, TableActions, ConfirmModal, EditPersonalInformationModal},
    mixins: [modals],
    props: {
        contact: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            contactPersonalInformation: [],
            config: {
                columns: [
                    {
                        headline: ""
                    },
                    {
                        headline: ""
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end"
                    }
                ]
            },
            configSmall: {
                columns: [
                    {
                        headline: "",
                        hidden: true
                    },
                    {
                        headline: ""
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end"
                    }
                ]
            },
            isLoading: false,
            personalInformationToEdit: null,
            personalInformationToDelete: null,
            modal: null,
            deleteModal: null,
            newUpdated: moment().unix()
        }
    },
    computed: {
        categories() {
            const cats = {};

            if (this.contact.birthday) {
                cats.Personals = {
                    full: true,
                    rows: [
                        {
                            cells: [
                                {
                                    text: "Birthday"
                                },
                                {
                                    // this.formatDate(this.contact.birthday)
                                    text: this.formatDate(this.contact.birthday)
                                },
                                {
                                    type: "actions",
                                    hideActions: true
                                }
                            ]
                        }
                    ]
                }

            }


            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            const informationSorted = this.contactPersonalInformation.sort(function (a, b) {
                if (a.contactPersonalInformationCategory.title < b.contactPersonalInformationCategory.title) {
                    return -1;
                }
                if (a.contactPersonalInformationCategory.title > b.contactPersonalInformationCategory.title) {
                    return 1;
                }
                return 0;
            });

            informationSorted.forEach(info => {

                const splitResult = info.value.split(':')

                let key = info.contactPersonalInformationCategory.title


                if (!Object.keys(cats).map(x => x.toLowerCase()).includes(key.toLowerCase())) {
                    cats[key] = {
                        full: !!splitResult[1],
                        rows: []
                    }
                }

                key = Object.keys(cats).find(x => x.toLowerCase() === key.toLowerCase());


                let years = "";
                if (key.toLowerCase() === "family" && splitResult[2]) {
                    years = moment().diff(splitResult[2], 'years', false);
                }


                const row = [
                    {
                        text: key.toLowerCase() === "family" ?
                            (splitResult[0] === "" ? info.contactPersonalInformationCategory.title : splitResult[0]) :
                            info.contactPersonalInformationCategory.title,
                    },
                    {
                        info: key.toLowerCase() === "family" ?
                            (splitResult[2] === "" ? splitResult[1] : splitResult[1] + (isNaN(years) ? "" : " (" + years + ")")) :
                            info.value,
                        type: 'info',
                    },
                    {
                        type: "actions",
                        id: info.id
                    }
                ]

                cats[key].rows.push(row);


                cats[key].rows = cats[key].rows.sort(function (a, b) {
                    if (a[0].text < b[0].text) {
                        return -1;
                    }
                    if (a[0].text > b[0].text) {
                        return 1;
                    }
                    return 0;
                });

            })

            return cats;
        },

    },
    mounted() {
        this.updateStart();
        this.loadPersonalInformations();
    },
    methods: {
        onModalClosed() {
            this.newUpdated = moment().unix()
            this.personalInformationToEdit = null
        },
        loadPersonalInformations() {
            if (this.contact.id > 0) {
                this.$axios.get('/api/contact_personal_informations?contact=' + this.contact.id)
                    .then((response) => {
                        this.contactPersonalInformation = response.data['hydra:member'];
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        addPersonalInformation() {
            this.personalInformationToEdit = null;
            this.showEditForm();
        },
        showEditForm() {
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.editPersonalInformationModal);
            })
        },
        contactPersonalInformationUpdated() {
            this.closeModal(this.modal);
            this.loadPersonalInformations();
        },
        updateStart() {
            this.isLoading = true;
        },
        deletePersonalInformation(id) {
            this.personalInformationToDelete = id;
            this.deleteModal = this.openModal(this.$refs.deletePersonalInformationModal);
        },
        doDeletePersonalInformation(id) {

            this.isLoading = true;
            this.$axios.delete('/api/contact_personal_informations/' + id).then(() => {
                this.loadPersonalInformations();
            });
        },
        editPersonalInformation(id) {
            this.personalInformationToEdit = JSON.parse(JSON.stringify(this.contactPersonalInformation.find(item => item.id === id)));
            this.showEditForm();
        },
    },
}

